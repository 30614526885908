import axios from 'axios';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaTrashAlt } from 'react-icons/fa';
import { toast } from "react-toastify";

export default function DeleteOrder({ order, loadTransactions }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletionReason, setDeletionReason] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleShowDeleteModal = () => setShowDeleteModal(true);

  const handleDeletionReasonChange = (e) => {
    setDeletionReason(e.target.value);
  };

  const handleDelete = () => {
    setLoading(true);

    axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

    const data = userRole === "4" ? {} : { deletion_reason: deletionReason };

    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/order/delete/${order.id}`,
      data: data
    })
      .then(() => {
        handleCloseDeleteModal();
        loadTransactions();
        toast.success('Order deleted');
        setLoading(false);
      })
      .catch(err => {
        handleCloseDeleteModal();
        console.error(err);
        toast.error(err.response?.data || 'An error occurred');
        setLoading(false);
      });
  };

  const handleRequestDelete = () => {
    setLoading(true);

    axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

    axios({
      method: "delete",
      url: `${process.env.REACT_APP_API_URL}/order/delete/${order.id}`,
      data: { deletion_reason: deletionReason }
    })
      .then(() => {
        handleCloseDeleteModal();
        loadTransactions();
        toast.info('Delete request sent to the restaurant owner for confirmation.');
        setLoading(false);
      })
      .catch(err => {
        handleCloseDeleteModal();
        console.error(err);
        toast.error(err.response?.data || 'An error occurred');
        setLoading(false);
      });
  };

  const userRole = localStorage.getItem("role");

  return (
    <>
      <FaTrashAlt color="red" onClick={handleShowDeleteModal} />

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userRole === "4" ? (
            <>
              Are you sure you want to delete this order?
            </>
          ) : (
            <>
              By confirming this, a request to delete the order will be sent to the restaurant owner for confirmation. Are you ready to proceed?
              <div className="my-3 form-group">
                <textarea
                  required
                  placeholder="Enter reason for deleting this order..."
                  onChange={handleDeletionReasonChange}
                  rows={3}
                  className="form-control"
                  width="100%"
                ></textarea>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          {userRole === "4" ? (
            <Button variant="danger" onClick={handleDelete} disabled={loading}>
              {loading ? "Deleting..." : "Delete"}
            </Button>
          ) : (
            <Button variant="danger" onClick={handleRequestDelete} disabled={loading}>
              {loading ? "Sending Request..." : "Send Request"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}