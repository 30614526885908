import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Bars } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CreateRequest from "../../components/CreateRequest";
import NavigationBar from "../../components/NavigationBar";
import ReturnBackButton from "../../components/ReturnBackButton";
import ViewOutStock from "../../components/ViewOutStock";
import ShiftToCurrentStock from "./ShiftToCurrentStock";

export default function InventoryIndex() {
  const [items, setItems] = useState([]);
  const [stock, setStock] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [count, setCount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    console.log(selectedRows);
  };

  const handleCountChange = (e) => {
    e.persist();
    setCount(e.target.value);
  };

  const handleAmountChange = (e) => {
    e.persist();
    setAmount(e.target.value);
  };

  const navigatie = useNavigate();

  const STORY_HEADERS = [
    {
      prop: "id",
      name: "Name",
      selector: (row) => row.stock.item_name,
    },
    {
      prop: "updated_at",
      name: "Measurement",
      selector: (row) => row.stock.measurement.name,
    },
    {
      prop: "count",
      name: "Instock",
      selector: (row) => row.stockcount,
    },
    {
      prop: "current_count",
      name: "Outstock",
      selector: (row) =>
        row.currents.length > 0 ? (
          <Badge bg="primary">Available</Badge>
        ) : (
          <Badge bg="dark">Unavailable</Badge>
        ),
    },
    {
      prop: "active",
      name: "Status",
      isSortable: true,
      selector: (row) =>
        row.stockcount > 1 ? (
          <Badge bg="success">Available</Badge>
        ) : (
          <Badge bg="danger">Unavailable</Badge>
        ),
    },
    {
      prop: "created_at",
      name: "Actions",
      selector: (row) => (
        <>
          <ShiftToCurrentStock loadStocks={loadStocks} id={row.id} />
          {row.currents.length > 0 && <ViewOutStock data={row.currents} />}
        </>
      ),
    },
  ];

  const addPlaceStock = (id, count) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .post(`${process.env.REACT_APP_API_URL}/stock/addStockCount`, {
        id: id,
        count: count,
        amount: amount,
      })
      .then(() => {
        handleClose();
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };

  const loadStocks = useCallback(async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    let t = await axios.get(
      `${process.env.REACT_APP_API_URL}/stock/items/${localStorage.getItem(
        "place"
      )}`
    );

    console.log(t.data.stocks);

    setStock(
      t.data.stocks.map((x) => ({
        ...x,
        stockcount: x.count,
        selected: false,
        count: 0,
        amount: 0,
      }))
    );
  });

  useEffect(() => {
    setLoading(true);
    loadStocks().then(() => setLoading(false));
  }, []);

  return (
    <>
      <NavigationBar />
      <div
        className="container-fluid"
        style={{
          padding: "2rem 0 0 0",
        }}
      >
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-row justify-content-start">
            <div
              className="bg-danger me-3 mt-2"
              style={{ width: "1rem", height: "1rem" }}
            >
              {" "}
            </div>
            <h4 style={{ fontWeight: "700" }}>Food items stock &nbsp; </h4>
            <ReturnBackButton />
          </div>

          <div className="pe-3 d-flex flex-row justify-content-end">
            <CreateRequest loadStocks={loadStocks} items={selectedRows} />
            <div
              className="btn btn-dark text-white me-3"
              onClick={() => navigatie("/stockhistory")}
            >
              History
            </div>
            <div
              className="btn btn-dark text-white me-3"
              onClick={() => navigatie("/pastrequests")}
            >
              Requisitions
            </div>
          </div>
        </div>
        <div className="container mt-5">
          {loading ? (
            <Bars
              heigth="100"
              width="1400"
              color="red"
              ariaLabel="loading-indicator"
            />
          ) : (
            <DataTable
              columns={STORY_HEADERS}
              data={stock}
              selectableRows
              pagination
              onSelectedRowsChange={handleSelectedRowsChange}
            />
          )}
        </div>
      </div>
    </>
  );
}
