import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function CreateRequest({ items, loadStocks }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [stock, setStock] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleAmountChange = (e, id) => {
    let itemIndex = stock.findIndex((x) => x.id == id);

    let content = [...stock];
    content[itemIndex].count = e.target.value;

    setStock([...content]);
  };

  const handlePriceChange = (e, id) => {
    let itemIndex = stock.findIndex((x) => x.id == id);

    let content = [...stock];
    content[itemIndex].amount = e.target.value;

    setStock([...content]);
  };

  useEffect(() => {
    setStock([...items]);
  });

  const handleSubmit = () => {
    console.log(stock);
    setLoading(true);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .post(`${process.env.REACT_APP_API_URL}/stock/request/create`, {
        stock: stock,
        place_id: localStorage.getItem("place"),
      })
      .then((res) => {
        handleClose();
        toast.success(res.data);
       window.location.reload()
      })
      .catch((err) => {
        toast.error(err.response.data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div onClick={handleShow} className="btn btn-dark text-white me-3">
        Create Request
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Make request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-2">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <b>Quantity</b>
            </div>
            <div className="col-md-4">
              <b>Price</b>
            </div>
          </div>
          {stock?.map((i) => (
            <div key={i.id} className="row mb-2">
              <div className="col-md-4">
                <p>
                  {i.stock.item_name} (in {i.stock.measurement.name})
                </p>
              </div>
              <div className="col-md-4">
                <input
                  className="form-control"
                  placeholder="Quantity"
                  onChange={(e) => handleAmountChange(e, i.id)}
                />
              </div>
              <div className="col-md-4">
                <input
                  className="form-control"
                  placeholder="Price per unit"
                  onChange={(e) => handlePriceChange(e, i.id)}
                />
              </div>
            </div>
          ))}
          <div className="mt-4 btn btn-danger" onClick={handleSubmit}>
            {loading ? "Submitting..." : "Submit"}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
