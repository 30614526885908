import React from "react";
import { ExportJsonCsv } from "react-export-json-csv";

export default function PDFDocument({ headers, data }) {
  return (
    <ExportJsonCsv headers={headers} items={data} className="btn btn-danger">
      Export
    </ExportJsonCsv>
  );
}
