import moment from "moment";
import React, { useEffect, useState } from "react";
import NavigationBar from "../components/NavigationBar";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Col, Row, Table } from "react-bootstrap";
import axios from "axios";
import { Bars } from "react-loader-spinner";

export default function DeletedDrinkItems() {
    const STORY_HEADERS = [
      {
        prop: "created_at",
        title: "Date",
        cell: (row) => moment(row.created_at).format("DD-MM-YYYY H:mm"),
      },
      {
        prop: "order_id",
        title: "Name",
        cell: (row) => row.drink.name,
      },
      {
        prop: "menu_id",
        title: "Order No",
        cell: (row) => row.order.order_number,
      },
      {
        prop: "price",
        title: "Price",
        cell: (row) => row.price,
      },
      {
        prop: "quantity",
        title: "Quantity",
        cell: (row) => row.quantity,
      },
      {
        prop: "note",
        title: "Note",
        cell: (row) => row.note,
      },
    ];
  
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      document.body.style.backgroundColor = "#f7f7f7";
  
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/order/deleted/drinkitems/${localStorage.getItem("place")}`
        )
        .then((res) => {
          setItems(res.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }, []);
  
    return (
      <>
        <NavigationBar />
        <div
          className="container-fluid"
          style={{
            padding: "2rem 0 0 0",
          }}
        >
          <div className="d-flex flex-row justify-content-start">
            <div
              className="bg-danger me-3 mt-2"
              style={{ width: "1rem", height: "1rem" }}
            >
              {" "}
            </div>
            <h4 style={{ fontWeight: "700" }}>Removed Drink Items &nbsp; </h4>
          </div>
          <div className="container">
            <div className="mt-3">
              {loading ? (
                <Bars
                  heigth="100"
                  width="1400"
                  color="red"
                  ariaLabel="loading-indicator"
                />
              ) : (
                <DatatableWrapper
                  body={items}
                  headers={STORY_HEADERS}
                  paginationOptionsProps={{
                    initialState: {
                      rowsPerPage: 10,
                      options: [5, 10, 15, 20],
                    },
                  }}
                >
                  <Row className="mb-4 p-2">
                    <Col
                      xs={12}
                      lg={4}
                      className="d-flex flex-col justify-content-end align-items-end"
                    >
                      <Filter classes={{ clearButton: "btn-danger" }} />
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      lg={4}
                      className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                    >
                      <PaginationOptions alwaysShowPagination="true" />
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      lg={4}
                      className="d-flex flex-col justify-content-end align-items-end"
                    >
                      <Pagination classes={{ button: "btn-danger" }} />
                    </Col>
                  </Row>
                  <Table>
                    <TableHeader />
                    <TableBody />
                  </Table>
                </DatatableWrapper>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
  