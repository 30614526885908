import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Badge, Col, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Bars } from "react-loader-spinner";
import AddMenuStockCount from "../../components/AddMenuStockCount";
import NavigationBar from "../../components/NavigationBar";
import ReturnBackButton from "../../components/ReturnBackButton";

export default function Restaurant() {
  const [stocks, setStock] = useState([]);

  const loadStockItems = useCallback(async () => {
    let t = await axios.get(
      `${process.env.REACT_APP_API_URL}/stock/items/${localStorage.getItem(
        "place"
      )}`
    );
    setStock(t.data.items);
  });

  const STORY_HEADERS = [
    {
      prop: "menu_name",
      title: "Name",
      isFilterable: true,
      isSortable: true,
    },
    {
      prop: "price",
      title: "Price",
      isSortable: true,
    },
    {
      prop: "status",
      title: "Availability",
      isSortable: true,
      cell: (row) =>
        row.status ? (
          <Badge bg="success">Available</Badge>
        ) : (
          <Badge bg="danger">Not Available</Badge>
        ),
    },
    {
      prop: "id",
      title: "Actions",
      cell: (row) => (
        <>
          <AddMenuStockCount menuID={row.id} />
        </>
      ),
    },
  ];

  const [food, setFood] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadFood = useCallback(async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    try {
      let f = await axios.get(
        `${process.env.REACT_APP_API_URL}/menu/place/${localStorage.getItem(
          "place"
        )}`
      );

      let t = await axios.get(
        `${process.env.REACT_APP_API_URL}/type/place/${localStorage.getItem(
          "place"
        )}`
      );

      setFood(f.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    document.body.style.background = "#f7f7f7";
    loadFood();
    loadStockItems();
  }, []);

  return (
    <>
      <NavigationBar />
      <div
        className="container-fluid"
        style={{
          padding: "2rem 0 0 0",
        }}
      >
        <div className="d-flex flex-row justify-content-start">
          <div
            className="bg-danger me-3 mt-2"
            style={{ width: "1rem", height: "1rem" }}
          >
            {" "}
          </div>
          <h4 style={{ fontWeight: "700" }}>Items</h4>
          <ReturnBackButton />
        </div>
        <div className="container">
          <div className="mt-3">
            {loading ? (
              <Bars
                heigth="100"
                width="1400"
                color="red"
                ariaLabel="loading-indicator"
              />
            ) : (
              <DatatableWrapper
                body={food}
                headers={STORY_HEADERS}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 10,
                    options: [5, 10, 15, 20],
                  },
                }}
              >
                <Row className="mb-4 p-2">
                  <Col
                    xs={12}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                  >
                    <Filter classes={{ clearButton: "btn-danger" }} />
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                  >
                    <PaginationOptions alwaysShowPagination="true" />
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                  >
                    <Pagination classes={{ button: "btn-danger" }} />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
