import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Card } from "react-bootstrap";

ChartJS.register(CategoryScale, Filler, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const options = {
  responsive: true,
  maintainAspectRatio: false,
};

const SalesLineChart = ({ data }) => {
  return (
    <Card>
      <Card.Body>
        <div style={{ height: 350 }}>
          <Line
            options={options}
            data={{
              labels: data.map(({ month }) => month),
              datasets: [
                {
                  fill: true,
                  label: "SALES",
                  data: data.map((sale) => sale.total_amount),
                  borderColor: "rgb(220, 53, 69)",
                  backgroundColor: "rgba(220, 53, 69, 0.5)",
                },
              ],
            }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default SalesLineChart;
