import React, { useEffect, useState } from "react";
import axios from "axios";
// import { Tab, Tabs } from "react-bootstrap";
import FoodCard from "./FoodCard";
import "../rts_tabs.css"
import { Bars } from "react-loader-spinner";
import { Tabs, Tab } from "react-tabs-scrollable";

export default function FoodTypes() {
  const [types, setTypes] = useState([]);
  const [food, setFood] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeFoodType, setActiveFoodType] = useState(null);

  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    setLoading(true);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/type/place/${localStorage.getItem(
          "place"
        )}`
      )
      .then((res) => {
        setTypes(res.data.types);
        setFood(res.data.food);
        console.log(res.data.food);
        setActiveFoodType(res.data.types?.length > 0 ? res.data.types[0].id : null)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  return (
    <>
      {!loading ? (
        types?.length > 0 ? (
          <div>
            <Tabs activeTab={activeTab} activeTabStyle={{ backgroundColor: 'red' }} onTabClick={onTabClick} styles={{ tabBarActiveColor: "red" }}>
              {types?.map((t) => (
                <Tab className="color-blue"  key={t.id} onClick={() => {
                  setActiveFoodType(t.id)
                }} >{t.name}</Tab>
              ))}
            </Tabs>
            <div className="row">
              {food
                .filter((f) => f.type_id == activeFoodType)
                .map((f) => (
                  <FoodCard key={f.id} food={f} types={types} />
                ))}
            </div>
          </div>
        ) : (
          <div className="mt-3 text-center">
            <h3>No food items added yet.</h3>
          </div>
        )
      ) : (
        <Bars
          heigth="100"
          width="1400"
          color="red"
          ariaLabel="loading-indicator"
        />
      )}
    </>
  );
}
