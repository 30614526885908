import { useState } from "react";
import { FaCoins } from "react-icons/fa";
import NavigationBar from "../components/NavigationBar";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from "react-bs-datatable";
import { Badge, Button, Card, Col, Container, Form, Modal, Row, Spinner, Stack, Table } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Bars, ThreeDots } from "react-loader-spinner";

const place = localStorage.getItem("place");
const token = localStorage.getItem("token");

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const AmountCard = ({ label, amount, isLoading, icon = <FaCoins size={40} /> }) => {
  return (
    <div className="bg-danger p-4 d-flex flex-row justify-content-around align-items-center">
      <div className="text-dark">{icon}</div>
      {isLoading ? (
        <ThreeDots color="black" height={60} />
      ) : (
        <div className="text-white">
          <small>{label}</small>
          <h4 style={{ fontWeight: "800" }}>{amount.toLocaleString()} TZS</h4>
        </div>
      )}
    </div>
  );
};

const HEADERS = [
  {
    prop: "place",
    title: "Restaurant Name",
    isSortable: true,
    cell: (row) => row.place.name,
  },
  {
    prop: "amount",
    title: "Amount",
    isSortable: true,
    cell: (row) => row.amount.toLocaleString(),
  },
  {
    prop: "status",
    title: "Status",
    isSortable: true,
    cell: (row) =>
      row.status === 1 ? (
        <Badge pill bg="success">
          CASH IN
        </Badge>
      ) : (
        <Badge pill bg="danger">
          CASH OUT
        </Badge>
      ),
  },
  {
    prop: "created_at",
    title: "Created",
    isSortable: true,
    cell: (row) => moment(row.created_at).format("LLL"),
  },
];

const CashInModal = () => {
  const [amount, setAmount] = useState("");
  const [show, setShow] = useState(false);
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (amount) => axios.post("/cash/store/cash-in-out", { amount, status: 1, place_id: place }),
    onSuccess: (res) => {
      toast.success("CASH INSERTED SUCCESSFULLY");
      setShow(false);
      setAmount("");
      queryClient.invalidateQueries({ queryKey: ["current-cash-in-cash-out"] });
    },
  });

  return (
    <>
      <Button variant="success" onClick={() => setShow(true)}>
        CASH IN
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setAmount("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>CASH IN</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            mutate(amount);
          }}
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>AMOUNT</Form.Label>
              <Form.Control type="number" required onChange={(e) => setAmount(e.target.value)} value={amount} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
                <Stack direction="horizontal" gap={2}>
                  <Spinner as="span" animation="grow" size="sm" />
                  <span>SAVING...</span>
                </Stack>
              ) : (
                "SAVE"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const CashOutModal = () => {
  const [amount, setAmount] = useState("");
  const [show, setShow] = useState(false);
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (amount) => axios.post("/cash/store/cash-in-out", { amount, status: 2, place_id: place }),
    onSuccess: (res) => {
      toast.success("CASH REMOVED SUCCESSFULLY");
      setShow(false);
      setAmount("");
      queryClient.invalidateQueries({ queryKey: ["current-cash-in-cash-out"] });
    },
  });

  return (
    <>
      <Button onClick={() => setShow(true)}>CASH OUT</Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setAmount("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>CASH OUT</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            mutate(amount);
          }}
        >
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>AMOUNT</Form.Label>
              <Form.Control type="number" required onChange={(e) => setAmount(e.target.value)} value={amount} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
                <Stack direction="horizontal" gap={2}>
                  <Spinner as="span" animation="grow" size="sm" />
                  <span>SAVING...</span>
                </Stack>
              ) : (
                "SAVE"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const CashInCashOut = () => {
  const { isLoading, data, isFetching } = useQuery({
    queryKey: ["current-cash-in-cash-out"],
    queryFn: () => axios.get(`/cash/report/current/cash-in-out/${place}`).then((res) => res.data.data),
  });

  return (
    <>
      <NavigationBar />
      <Container
        fluid
        style={{
          background: "#f7f7f7",
          height: "100vh",
          padding: "2rem 0 0 0",
        }}
      >
        <div className="d-flex flex-row justify-content-start">
          <div className="bg-danger me-3 mt-2" style={{ width: "1rem", height: "1rem" }}>
            {" "}
          </div>
          <h4 style={{ fontWeight: "700" }}>Cash In - Cash Out</h4>
        </div>
        <Container>
          <Card>
            <Card.Body>
              <Stack direction="horizontal" className="justify-content-between mb-3">
                <span>{moment().format("LLL")}</span>
                <Stack direction="horizontal" gap={3}>
                  <CashInModal />
                  <CashOutModal />
                </Stack>
              </Stack>

              <Stack direction="horizontal" gap={3}>
                <Col>
                  <AmountCard
                    label="TOTAL CURRENT REGISTER"
                    isLoading={isFetching}
                    amount={data?.total_current_register ?? 0}
                  />
                </Col>
                <Col>
                  <AmountCard label="TOTAL CASH IN (TODAY)" isLoading={isFetching} amount={data?.total_cash_in ?? 0} />
                </Col>
                <Col>
                  <AmountCard label="TOTAL CASH OUT (TODAY)" isLoading={isFetching} amount={data?.total_cash_out ?? 0} />
                </Col>
              </Stack>
            </Card.Body>
          </Card>

          <Card className="mt-3">
            <Card.Body>
              {isLoading ? (
                <Bars height={100} width={1400} color="red" />
              ) : (
                <DatatableWrapper
                  body={data?.listCashInCashOut}
                  headers={HEADERS}
                  paginationOptionsProps={{
                    initialState: {
                      rowsPerPage: 10,
                      options: [5, 10, 15, 20],
                    },
                  }}
                >
                  <Row className="mb-4 p-2">
                    <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                      <Filter classes={{ clearButton: "btn-danger" }} />
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      lg={4}
                      className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                    >
                      <PaginationOptions alwaysShowPagination="true" />
                    </Col>
                    <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                      <Pagination classes={{ button: "btn-danger" }} />
                    </Col>
                  </Row>
                  <Table>
                    <TableHeader />
                    <TableBody />
                  </Table>
                </DatatableWrapper>
              )}
            </Card.Body>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default CashInCashOut;
