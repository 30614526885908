import moment from "moment";
import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";

export default function ViewOutStock({ data }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <FaEye className="text-danger ms-4" size={17} onClick={handleShow} />

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Outstock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Category</th>
                <th>Quantity</th>
                <th>Issuer</th>
                <th>Date of issue</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d.type.name}</td>
                  <td>{d.quantity}</td>
                  <td>{d.user.name}</td>
                  <td>{moment(d.created_at).format("DD-MM-YYYY")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}
