import React from 'react';
import logo from "../assets/images/tayarilogo2.png";

const NotFound = () => {
  return (
    <div className='flex w-full h-screen flex-col gap-2 text-center items-center justify-center text-black bg-white'>
      <img src={logo} className='w-[300px]' />
      <div className='font-bold text-[30px]'>Error, 404!</div>
      <div className='font-semibold text-[20px] text-red-500'>Not Found</div>
    </div>
  )
};

export default NotFound;