import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Set the authorization header
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

const SalesChart = () => {
  const [salesData, setSalesData] = useState([]);
  const [timeline, setTimeline] = useState('daily');
  const place = localStorage.getItem('place');

  useEffect(() => {
    if (place) {
      axios.get(`https://api.tayari.co.tz/api/v1/sales/report/sales-chart-live/${place}/${timeline}`)
        .then(response => {
          const data = response.data;
          if (data && data.report && data.report.sales) {
            setSalesData(data.report.sales);
            console.log('Sales data fetched:', data);
          }
        })
        .catch(error => {
          console.error('Error fetching sales data:', error);
          toast.error('Error fetching sales data. Please try again later.');
        });
    }
  }, [place, timeline]);

  const chartData = {
    labels: salesData.map(sale => sale.period || 'Total'),
    datasets: [
      {
        label: 'Total Sales',
        data: salesData.map(sale => sale.total_cost),
        fill: false,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
      },
    ],
  };

  const handleTimelineChange = (event) => {
    setTimeline(event.target.value);
  };

  return (
    <div>
      <h2>Sales Chart</h2>
      <select value={timeline} onChange={handleTimelineChange}>
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
        <option value="yearly">Yearly</option>
        <option value="lifetime">Lifetime</option>
      </select>
      <Line data={chartData} />
      <ToastContainer />
    </div>
  );
};

export default SalesChart;