import React from "react";
import axios from "axios";
import { Card } from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { useQuery } from "@tanstack/react-query";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const place = localStorage.getItem("place");
const token = localStorage.getItem("token");

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const options = {
  responsive: true,
  maintainAspectRatio: false,
};

const SalesBarChart = (params) => {
  const { isLoading, data } = useQuery({
    queryKey: ["sales"],
    queryFn: () => axios.get(`/sales/report/sales-chart/${place}`).then((res) => res.data.data.sales),
  });

  if (isLoading) return <Bars height={100} width={1400} color="red" />;

  return (
    <Card>
      <Card.Body>
        <Card.Title className="d-flex align-items-center ">
          <div className="bg-danger" style={{ height: "1rem", width: "1rem", marginRight: 10 }}></div>
          <div style={{ fontSize: 24 }}>MONTHLY SALES REPORTS</div>
        </Card.Title>
        <div style={{ height: 350 }}>
          <Bar
            options={options}
            data={{
              labels: data.map((sale) => sale?.month),
              datasets: [
                {
                  label: "SALES",
                  data: data.map((sale) => sale.total_cost),
                  backgroundColor: "#dc3545",
                },
              ],
            }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default SalesBarChart;
