import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NavigationBar from "../components/NavigationBar";

export default function Schedule() {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    monday: false,
    mondayOpeningTime: "",
    mondayClosingTime: "",
    tuesday: false,
    tuesdayOpeningTime: "",
    tuesdayClosingTime: "",
    wednesday: false,
    wednesdayOpeningTime: "",
    wednesdayClosingTime: "",
    thursday: false,
    thursdayOpeningTime: "",
    thursdayClosingTime: "",
    friday: false,
    fridayOpeningTime: "",
    fridayClosingTime: "",
    saturday: false,
    saturdayOpeningTime: "",
    saturdayClosingTime: "",
    sunday: false,
    sundayOpeningTime: "",
    sundayClosingTime: "",
  });

  const buttonStyle = {
    fontWeight: "800",
    padding: "0.7rem 2rem",
    background: "red",
    color: "white",
  };

  const handleMondayChange = (e) => {
    e.persist();
    setValues({
      ...values,
      monday: !values.monday,
    });
  };

const handleMondayOpeningChange = e => {
  e.persist()
  setValues({
    ...values,
    mondayOpeningTime: e.target.value
  })
}

const handleMondayClosingChange = e => {
  e.persist()
  setValues({
    ...values,
    mondayClosingTime: e.target.value
  })
}

  const handleTuesdayChange = (e) => {
    e.persist();
    setValues({
      ...values,
      tuesday: !values.tuesday,
    });
  };

  const handleTuesdayOpeningChange = e => {
    e.persist()
    setValues({
      ...values,
      tuesdayOpeningTime: e.target.value
    })
  }

  const handleTuesdayClosingChange = e => {
    e.persist()
    setValues({
      ...values,
      tuesdayClosingTime: e.target.value
    })
  }

  const handleWednesdayChange = (e) => {
    e.persist();
    setValues({
      ...values,
      wednesday: !values.wednesday,
    });
  };

  const handleWednesdayOpeningChange = e => {
    e.persist()
    setValues({
      ...values,
      wednesdayOpeningTime: e.target.value
    })
  }

  const handleWednesdayClosingChange = e => {
    e.persist()
    setValues({
      ...values,
      wednesdayClosingTime: e.target.value
    })
  }

  const handleThursdayChange = (e) => {
    e.persist();
    setValues({
      ...values,
      thursday: !values.thursday,
    });
  };

  const handleThursdayOpeningChange = e => {
    e.persist()
    setValues({
      ...values,
      thursdayOpeningTime: e.target.value
    })
  }

  const handleThursdayClosingChange = e => {
    e.persist()
    setValues({
      ...values,
      thursdayClosingTime: e.target.value
    })
  }

  const handleFridayChange = (e) => {
    e.persist();
    setValues({
      ...values,
      friday: !values.friday,
    });
  };

  const handleFridayOpeningChange = e => {
    e.persist()
    setValues({
      ...values,
      fridayOpeningTime: e.target.value
    })
  }

  const handleFridayClosingChange = e => {
    e.persist()
    setValues({
      ...values,
      fridayClosingTime: e.target.value
    })
  }

  const handleSaturdayChange = (e) => {
    e.persist();
    setValues({
      ...values,
      saturday: !values.saturday,
    });
  };

  const handleSaturdayOpeningChange = e => {
    e.persist()
    setValues({
      ...values,
      saturdayOpeningTime: e.target.value
    })
  }

  const handleSaturdayClosingChange = e => {
    e.persist()
    setValues({
      ...values,
      saturdayClosingTime: e.target.value
    })
  }

  const handleSundayChange = (e) => {
    e.persist();
    setValues({
      ...values,
      sunday: !values.sunday,
    });
  };

  const handleSundayOpeningChange = e => {
    e.persist()
    setValues({
      ...values,
     sundayOpeningTime: e.target.value
    })
  }

  const handleSundayClosingChange = e => {
    e.persist()
    setValues({
      ...values,
     sundayClosingTime: e.target.value
    })
  }

  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/schedule/${localStorage.getItem(
          "place"
        )}`
      )
      .then((res) => {
        console.log(res.data);

        setValues({
          monday:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 1).open
              : true,
          mondayOpeningTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 1).opening_time
              : "00:00",
          mondayClosingTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 1).closing_time
              : "00:00",
          tuesday:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 2).open
              : true,
          tuesdayOpeningTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 2).opening_time
              : "00:00",
          tuesdayClosingTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 2).closing_time
              : "00:00",
          wednesday:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 3).open
              : true,
          wednesdayOpeningTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 3).opening_time
              : "00:00",
          wednesdayClosingTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 3).closing_time
              : "00:00",
          thursday:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 4).open
              : true,
          thrusdayOpeningTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 4).opening_time
              : "00:00",
          thrusdayClosingTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 4).closing_time
              : "00:00",
          friday:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 5).open
              : true,
          fridayOpeningTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 5).opening_time
              : "00:00",
          fridayClosingTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 5).closing_time
              : "00:00",
          saturday:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 6).open
              : true,
          saturdayOpeningTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 6).opening_time
              : "00:00",
          saturdayClosingTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 6).closing_time
              : "00:00",
          sunday:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 7).open
              : true,
          sundayOpeningTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 7).opening_time
              : "00:00",
          sundayClosingTime:
            res.data.length > 0
              ? res.data.find((x) => x.day_id === 7).closing_time
              : "00:00",
        });
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    const singleday = moment().format("dddd");

    axios
      .post(`${process.env.REACT_APP_API_URL}/schedule/update`, {
        place: localStorage.getItem("place"),
        monday: values.monday,
        tuesday: values.tuesday,
        wednesday: values.wednesday,
        thursday: values.thursday,
        friday: values.friday,
        saturday: values.saturday,
        sunday: values.sunday,
        mondayOpeningTime: values.mondayOpeningTime || "00:00",
        mondayClosingTime: values.mondayClosingTime || "00:00",
        tuesdayOpeningTime: values.tuesdayOpeningTime || "00:00",
        tuesdayClosingTime: values.tuesdayClosingTime || "00:00",
        wednesdayOpeningTime: values.wednesdayOpeningTime || "00:00",
        wednesdayClosingTime: values.wednesdayClosingTime || "00:00",
        thursdayOpeningTime: values.thursdayOpeningTime || "00:00",
        thursdayClosingTime: values.thursdayClosingTime || "00:00",
        fridayOpeningTime: values.fridayOpeningTime || "00:00",
        fridayClosingTime: values.fridayClosingTime || "00:00",
        saturdayOpeningTime: values.saturdayOpeningTime || "00:00",
        saturdayClosingTime: values.saturdayClosingTime || "00:00",
        sundayOpeningTime: values.sundayOpeningTime || "00:00",
        sundayClosingTime: values.sundayClosingTime || "00:00",
        day: singleday,
      })
      .then((res) => {
        setLoading(false);
        toast.success(res.data);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data);
      });
  };

  return (
    <>
      <NavigationBar />

      <div
        className="container-fluid"
        style={{
          padding: "2rem 0 0 0",
        }}
      >
        <div className="d-flex flex-row justify-content-start">
          <div
            className="bg-danger me-3 mt-2"
            style={{ width: "1rem", height: "1rem" }}
          >
            {" "}
          </div>
          <h4 style={{ fontWeight: "700" }}>Schedule &nbsp; </h4>
        </div>

        <div className="container">
          <form onSubmit={handleSubmit} className="mt-3 row">
            <div className="col-md-12">
              <div
                className="card p-2"
                style={{
                  width: "100%",
                  background: "white",
                  color: "black",
                }}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <p>
                        Switch on or off the days when your restaurant/place is
                        going to be open or closed.
                      </p>
                    </div>
                    <div className="col-md-6 mb-4">
                      {/* Start of column */}

                      <div className="border border-danger p-4 mb-3">
                        <p>Monday</p>

                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={values.monday}
                            onChange={handleMondayChange}
                            checked={values.monday}
                          />
                          <label className="form-check-label">Available</label>
                        </div>

                        <div className="d-flex flex-row justify-content-start my-3">
                          <div className="form-group">
                            <label>Opening Time</label>
                            <input
                              type="time"
                              onChange={handleMondayOpeningChange}
                              value={values.mondayOpeningTime || "00:00"}
                              className="form-control"
                            />
                          </div>

                          <div className="form-group ms-5">
                            <label>Closing Time</label>
                            <input
                              type="time"
                              onChange={handleMondayClosingChange}
                              value={values.mondayClosingTime || "00:00"}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="border border-danger p-4 mb-3">
                        <p>Tuesday</p>

                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={values.tuesday}
                            onChange={handleTuesdayChange}
                            checked={values.tuesday}
                          />
                          <label className="form-check-label">Available</label>
                        </div>

                        <div className="d-flex flex-row justify-content-start my-3">
                          <div className="form-group">
                            <label>Opening Time</label>
                            <input
                              type="time"
                              onChange={handleTuesdayOpeningChange}
                              value={values.tuesdayOpeningTime || "00:00"}
                              className="form-control"
                            />
                          </div>

                          <div className="form-group ms-5">
                            <label>Closing Time</label>
                            <input
                              type="time"
                              onChange={handleTuesdayClosingChange}
                              value={values.tuesdayClosingTime || "00:00"}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="border border-danger p-4 mb-3">
                        <p>Wednesday</p>

                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={values.wednesday}
                            onChange={handleWednesdayChange}
                            checked={values.wednesday}
                          />
                          <label className="form-check-label">Available</label>
                        </div>

                        <div className="d-flex flex-row justify-content-start my-3">
                          <div className="form-group">
                            <label>Opening Time</label>
                            <input
                              type="time"
                              onChange={handleWednesdayOpeningChange}
                              value={values.wednesdayOpeningTime || "00:00"}
                              className="form-control"
                            />
                          </div>

                          <div className="form-group ms-5">
                            <label>Closing Time</label>
                            <input
                              type="time"
                              onChange={handleWednesdayClosingChange}
                              value={values.wednesdayClosingTime || "00:00"}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="border border-danger p-4 mb-3">
                        <p>Thursday</p>

                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={values.thursday}
                            onChange={handleThursdayChange}
                            checked={values.thursday}
                          />
                          <label className="form-check-label">Available</label>
                        </div>

                        <div className="d-flex flex-row justify-content-start my-3">
                          <div className="form-group">
                            <label>Opening Time</label>
                            <input
                              type="time"
                              onChange={handleThursdayOpeningChange}
                              value={values.thursdayOpeningTime || "00:00"}
                              className="form-control"
                            />
                          </div>

                          <div className="form-group ms-5">
                            <label>Closing Time</label>
                            <input
                              type="time"
                              onChange={handleThursdayClosingChange}
                              value={values.thursdayClosingTime || "00:00"}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      {/* End of column */}
                    </div>

                    <div className="col-md-6 mb-4">
                      {/* Start of column */}

                      <div className="border border-danger p-4 mb-3">
                        <p>Friday</p>

                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={values.friday}
                            onChange={handleFridayChange}
                            checked={values.friday}
                          />
                          <label className="form-check-label">Available</label>
                        </div>

                        <div className="d-flex flex-row justify-content-start my-3">
                          <div className="form-group">
                            <label>Opening Time</label>
                            <input
                              type="time"
                              onChange={handleFridayOpeningChange}
                              value={values.fridayOpeningTime || "00:00"}
                              className="form-control"
                            />
                          </div>

                          <div className="form-group ms-5">
                            <label>Closing Time</label>
                            <input
                              type="time"
                              onChange={handleFridayClosingChange}
                              value={values.fridayClosingTime || "00:00"}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="border border-danger p-4 mb-3">
                        <p>Saturday</p>

                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={values.saturday}
                            onChange={handleSaturdayChange}
                            checked={values.saturday}
                          />
                          <label className="form-check-label">Available</label>
                        </div>

                        <div className="d-flex flex-row justify-content-start my-3">
                          <div className="form-group">
                            <label>Opening Time</label>
                            <input
                              type="time"
                              onChange={handleSaturdayOpeningChange}
                              value={values.saturdayOpeningTime || "00:00"}
                              className="form-control"
                            />
                          </div>

                          <div className="form-group ms-5">
                            <label>Closing Time</label>
                            <input
                              type="time"
                              onChange={handleSaturdayClosingChange}
                              value={values.saturdayClosingTime || "00:00"}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="border border-danger p-4 mb-3">
                        <p>Sunday</p>

                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={values.sunday}
                            onChange={handleSundayChange}
                            checked={values.sunday}
                          />
                          <label className="form-check-label">Available</label>
                        </div>

                        <div className="d-flex flex-row justify-content-start my-3">
                          <div className="form-group">
                            <label>Opening Time</label>
                            <input
                              type="time"
                              onChange={handleSundayOpeningChange}
                              value={values.sundayOpeningTime || "00:00"}
                              className="form-control"
                            />
                          </div>

                          <div className="form-group ms-5">
                            <label>Closing Time</label>
                            <input
                              type="time"
                              onChange={handleSundayClosingChange}
                              value={values.sundayClosingTime || "00:00"}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      {/*  End of column */}
                    </div>

                    <div className="col-md-12 mt-3">
                      <div className="d-grid">
                        <button
                          className="btn"
                          type="submit"
                          style={buttonStyle}
                        >
                          {loading ? "Updating..." : "Update"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
