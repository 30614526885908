import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function FoodCard({ food, types }) {
  const [showDecide, setShowDecide] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const handleShow = () => {
    setValues({
      id: food.id,
      name: food.menu_name,
      price: food.price,
      time: food.time_takes_to_make,
      ingredients: food.ingredients,
      type: food.type_id,
      description: food.description,
      foodImage: food.banner,
      kilos: food.kilos,
    });
    setShow(true);
  };

  const [values, setValues] = useState({
    name: "",
    id: "",
    price: "",
    time: "",
    type: "",
    ingredients: "",
    description: "",
    kilos: "",
    foodImage: "",
  });

  const handleNameChange = (e) => {
    e.persist();
    setValues({
      ...values,
      name: e.target.value,
    });
  };

  const handleKilosChange = (e) => {
    e.persist();
    setValues({
      ...values,
      kilos: e.target.value,
    });
  };

  const handleTypeChange = (e) => {
    e.persist();
    setValues({
      ...values,
      type: e.target.value,
    });
  };

  const handleSizeChange = (e) => {
    e.persist();
    setValues({
      ...values,
      size: e.target.value,
    });
  };

  const handlePriceChange = (e) => {
    e.persist();
    setValues({
      ...values,
      price: e.target.value,
    });
  };

  const handleTimeChange = (e) => {
    e.persist();
    setValues({
      ...values,
      time: e.target.value,
    });
  };

  const handleDescriptionChange = (e) => {
    e.persist();
    setValues({
      ...values,
      description: e.target.value,
    });
  };

  const handleIngredientsChange = (e) => {
    e.persist();
    setValues({
      ...values,
      ingredients: e.target.value,
    });
  };

  const [notificationMsg, setNotificationMsg] = useState("");
  const [notificationLoading, setNotificationLoading] = useState(false);

  const handeNotificationMessage = (e) => {
    e.persist();
    setNotificationMsg(e.target.value);
  };

  const handleSendingNotification = (e) => {
    e.preventDefault();
    setNotificationLoading(true);

    let customMessage = `${notificationMsg}
    
Kuona zaidi tembelea
    
https://order.tayari.co.tz/home/restaurant/food?id=${food.id}&name=${food.menu_name}&banner=${food.banner}&price=${food.price}&place=${food.place_id}&description=${food.description}`


    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .post(`${process.env.REACT_APP_API_URL}/place/notify/customers`, {
        menu_id: food.id,
        body: customMessage,
      })
      .then(() => {
        setNotificationLoading(false);
        setNotificationMsg(false);
      })
      .catch((err) => {
        console.log(err);
        setNotificationLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("price", values.price);
    formData.append("type", values.type);
    formData.append("menu_id", values.id);
    formData.append("time", values.time);
    formData.append("time", values.time);
    formData.append("kilos", values.kilos);
    formData.append("ingredients", values.ingredients);
    selectedBanner !== null &&
      formData.append("banner", selectedBanner, selectedBanner.name);

    axios
      .post(`${process.env.REACT_APP_API_URL}/menu/update`, formData)
      .then(() => {
        handleClose();
        setLoading(false);
        window.location.reload();
        toast.success("Food item edited");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Failed to update food item");
      });
  };

  return (
    <div className="col-md-3">
      <div
        className="parent mb-3"
        onClick={() => {
          setShowDecide(true);
        }}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_SITE_URL}/images/food/${food.banner})`,
          backgroundSize: "cover",
          height: "12rem",
          borderRadius: "12px",
          position: 'relative'
        }}
      >
        <div id="overlay">
        </div>
        <p id="overlaytext">
            {food.menu_name} - TZS {food.price}
            <br />
            {food.type?.name}
          </p>
      </div>

      {/* Start decision modal */}
      <Modal
        show={showDecide}
        onHide={() => {
          setShowDecide(false);
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Options for {food.menu_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row" style={{ margin: "30px 0" }}>
            <div className="col-md-6 text-center">
              <div
                className="btn btn-danger px-3 py-3"
                onClick={() => {
                  setShowDecide(true);
                  setShowNotification(true);
                }}
              >
                Send Notification to your customers
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div
                className="btn btn-danger px-5 py-3"
                onClick={() => {
                  setShowDecide(false);
                  handleShow();
                }}
              >
                Edit details
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* End decision modal */}

      {/* Start of notification of food item modal */}
      <Modal
        show={showNotification}
        onHide={() => {
          setShowNotification(false);
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Notify your customers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSendingNotification}>
            <div className="form-group mb-3">
              <textarea
                className="form-control"
                placeholder="Enter message..."
                width="100%"
                rows="5"
                onChange={handeNotificationMessage}
              />
            </div>
            <div className="form-group mb-4">
              <button className="btn btn-danger">
                {notificationLoading ? "Sending.." : "Submit"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* End of notification of food item modal */}

      {/* Edit food item modal */}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Food Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-4 mb-3">
                {selectedBanner ? (
                  <img
                    alt="img"
                    width={"100%"}
                    src={URL.createObjectURL(selectedBanner)}
                  />
                ) : (
                  <img
                    alt={values.foodImage}
                    width={"100%"}
                    src={`${process.env.REACT_APP_SITE_URL}/images/food/${values.foodImage}`}
                  />
                )}
              </div>
              <div className="col-md-8 mb-3">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label>Item name</label>
                    <input
                      value={values.name}
                      onChange={handleNameChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Item price</label>
                    <input
                      className="form-control"
                      onChange={handlePriceChange}
                      value={values.price}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Cooking time</label>
                    <input
                      className="form-control"
                      onChange={handleTimeChange}
                      value={values.time}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Food type</label>
                    <select
                      className="form-control"
                      onChange={handleTypeChange}
                      defaultValue={values.type}
                    >
                      {types.length > 0 &&
                        types.map((t) => (
                          <option key={t.id} value={t.id}>
                            {t.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <input
                  type="file"
                  className="form-control"
                  id="logo"
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    setSelectedBanner(event.target.files[0]);
                  }}
                />
              </div>
              <div className="col-md-12 mb-3">
                <label>Item ingredients</label>
                <textarea
                  className="form-control"
                  onChange={handleIngredientsChange}
                  value={values.ingredients}
                  rows="4"
                ></textarea>
              </div>
              <div className="col-md-12 mb-3">
                <label>Item description</label>
                <textarea
                  className="form-control"
                  onChange={handleDescriptionChange}
                  value={values.description}
                  rows="4"
                ></textarea>
              </div>
              <div className="d-grid gap-2">
                <button className="btn btn-danger py-2" type="submit">
                  {loading ? "Updating..." : " Edit Food Item"}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* End of edit food item modal */}
    </div>
  );
}
