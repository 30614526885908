import React, { useState } from "react";
import { Nav, Offcanvas } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as CustomerSvg } from "../assets/svg/001 Customers.svg";
import { ReactComponent as FoodItemSvg } from "../assets/svg/002 Food items.svg";
import { ReactComponent as FoodTypeSvg } from "../assets/svg/003 Food types.svg";
import { ReactComponent as TableSvg } from "../assets/svg/004 tables.svg";
import { ReactComponent as DrinkSvg } from "../assets/svg/005 Drinks.svg";
import { ReactComponent as AuthSvg } from "../assets/svg/006 authentics.svg";
import { ReactComponent as ReviewSvg } from "../assets/svg/007 Reviews.svg";
import { ReactComponent as SettingSvg } from "../assets/svg/008 Settings.svg";
import { ReactComponent as ScheduleSvg } from "../assets/svg/009 Schedule.svg";
import { ReactComponent as RestaurantSvg } from "../assets/svg/100 Restaurant.svg";
import { ReactComponent as SupportSvg } from "../assets/svg/101 Supports.svg";
import { ReactComponent as TermsSvg } from "../assets/svg/102 Terms _ Conditions.svg";
import { ReactComponent as LogoutSvg } from "../assets/svg/103 Logout.svg";
import { FaCashRegister, FaChartArea, FaTrash, FaUserClock } from "react-icons/fa";

// import { ReactComponent as NewFeatureSvg } from "../assets/svg/newfeature.svg";
// import { ReactComponent as InventorySvg } from "../assets/svg/105 Inventory.svg";
// import { ReactComponent as SupplierSvg } from "../assets/svg/104 Supplier.svg";

export default function Drawer() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const linkStyle = {
    textDecoration: "none",
    color: "black",
    fontSize: "20px",
  };

  const review = () => {
    navigate("/reviews");
    handleClose();
  };

  const items = () => {
    navigate("/fooditems");
    handleClose();
  };

  const customers = () => {
    navigate("/customers");
    handleClose();
  };

  const terms = () => {
    navigate("/terms");
    handleClose();
  };

  const support = () => {
    navigate("/support");
    handleClose();
  };

  const table = () => {
    navigate("/tables");
    handleClose();
  };

  const settings = () => {
    navigate("/settings");
    handleClose();
  };

  const schedule = () => {
    navigate("/schedule");
    handleClose();
  };

  const types = () => {
    navigate("/types");
    handleClose();
  };

  const drinks = () => {
    navigate("/drinks");
    handleClose();
  };

  const inventory = () => {
    navigate("/inventory");
    handleClose();
  };

  const addon = () => {
    navigate("/addon");
    handleClose();
  };

  const suppliers = () => {
    navigate("/supplier");
    handleClose();
  };

  const deleteddrinks = () => {
    navigate("/deleteddrinks");
    handleClose();
  };

  const deletedfood = () => {
    navigate("/deletedfood");
    handleClose();
  };

  const authentication = () => {
    navigate("/authentication");
    handleClose();
  };

  const sessions = () => {
    navigate("/sessions");
    handleClose();
  };

  const cashInCashOut = () => {
    navigate("/cash-in-cash-out");
    handleClose();
  };

  const shifts = () => {
    navigate("/shifts");
    handleClose();
  };

  const reports = () => {
    navigate("/reports");
    handleClose();
  };

  const restaurants = () => {
    localStorage.removeItem("place");
    navigate("/places", { replace: true });
    handleClose();
  };

  const logout = () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .post(`${process.env.REACT_APP_API_URL}/logout`)
      .then(() => {
        localStorage.clear();
        navigate("/");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        onClick={handleShow}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_SITE_URL}/images/logos/${localStorage.getItem("placeimage")})`,
          backgroundSize: "cover",
          height: "3.8rem",
          borderRadius: "12px",
          width: "3.8rem",
        }}
      ></div>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>{/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}</Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            {localStorage.getItem("role") == 4 && (
              <>
                <Nav.Item>
                  <Nav.Link onClick={customers} eventKey="customers" style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <CustomerSvg height={20} width={20} className="me-3 mt-1" /> <p>Customers</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={items} eventKey="items" style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <FoodItemSvg height={20} width={20} className="me-3 mt-1" /> <p>Food Items</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link onClick={addon} eventKey="items" style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <FoodItemSvg height={20} width={20} className="me-3 mt-1" /> <p>Served with</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link onClick={types} eventKey="items" style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <FoodTypeSvg height={20} width={20} className="me-3 mt-1" /> <p>Food Types</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link onClick={table} eventKey="items" style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <TableSvg height={20} width={20} className="me-3 mt-1" /> <p>Tables</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link onClick={drinks} eventKey="items" style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <DrinkSvg height={20} width={20} className="me-3 mt-1" /> <p>Drinks</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link onClick={authentication} eventKey="items" style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <AuthSvg height={20} width={20} className="me-3 mt-1" /> <p>Authentication</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link onClick={deletedfood} eventKey="deletedfood" style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <FaTrash className="me-3 mt-1" size={20} /> <p>Deleted Food</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link onClick={deleteddrinks} eventKey="deleteddrinks" style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <FaTrash className="me-3 mt-1" size={20} /> <p>Deleted Drinks</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link onClick={review} eventKey="reviews" style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <ReviewSvg height={20} width={20} className="me-3 mt-1" /> <p>Reviews</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="settings" onClick={settings} style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <SettingSvg height={20} width={20} className="me-3 mt-1" /> <p>Settings</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="schedule" onClick={schedule} style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <ScheduleSvg height={20} width={20} className="me-3 mt-1" />
                      <p>Schedule</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey="settings" onClick={restaurants} style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <RestaurantSvg height={20} width={20} className="me-3 mt-1" />
                      <p>Restaurants</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="sessions" onClick={sessions} style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <FaUserClock height={20} width={20} className="me-3 mt-1" />
                      <p>Sessions</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="cash-in-cash-out" onClick={cashInCashOut} style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <FaCashRegister height={20} width={20} className="me-3 mt-1" />
                      <p>Cash In - Cash Out</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="shifts" onClick={shifts} style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <FaUserClock height={20} width={20} className="me-3 mt-1" />
                      <p>Shifts</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="reports" onClick={reports} style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <FaChartArea height={20} width={20} className="me-3 mt-1" />
                      <p>Reports</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
              <Nav.Link eventKey="support" onClick={suppliers} style={linkStyle}>
                <div className="d-flex flex-row justify-content-start">
                <SupplierSvg
                  height={20}
                  width={20}
                  className="me-3 mt-1"
                />  <p>Suppliers</p>
                <NewFeatureSvg
                  height={40}
                  width={40}
                  className="ms-2"
                />
                </div>
              </Nav.Link> 
            </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link eventKey="support" onClick={support} style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <SupportSvg height={20} width={20} className="me-3 mt-1" /> <p>Support</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link onClick={terms} eventKey="terms" style={linkStyle}>
                    <div className="d-flex flex-row justify-content-start">
                      <TermsSvg height={20} width={20} className="me-3 mt-1" />
                      <p>Terms & Conditions</p>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </>
            )}

            <Nav.Item>
              <Nav.Link onClick={logout} eventKey="logout" style={linkStyle}>
                <div className="d-flex flex-row justify-content-start">
                  <LogoutSvg height={20} width={20} className="me-3 mt-1" /> <p>Logout</p>
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
