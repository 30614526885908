import axios from "axios";
import { includes } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { FaPenAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import NavigationBar from "../components/NavigationBar";
import ReturnBackButton from "../components/ReturnBackButton";

export default function AddNewMenuStock() {
  const [stocks, setStock] = useState([]);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [quantity, setQuantity] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [stockID, setStockId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = "#f7f7f7";
    loadMenuStocks();
    loadStockItems();
  }, []);

  const loadMenuStocks = useCallback(async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    let t = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/stock/getMenuStock/${localStorage.getItem("menuID")}`
    );

    console.log(t.data);

    setItems(
      t.data.map((x) => ({
        id: x.place_stock_id,
        name: x.placestock.stock.item_name,
        quantity: x.amount_needed_to_make,
        measurement: x.placestock.stock.measurement.name,
      }))
    );
  });

  const loadStockItems = useCallback(async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    let t = await axios.get(
      `${process.env.REACT_APP_API_URL}/stock/items/${localStorage.getItem(
        "place"
      )}`
    );
    setStock(
      t.data.stocks.map((x) => ({
        value: x.id,
        selected: false,
        count: 0,
        name: x.stock.item_name,
        label: `${x.stock.item_name} in ${x.stock.measurement.name}`,
        measurement: x.stock.measurement.name,
      }))
    );

    console.log(t.data.stocks);
  });

  const handleSelectedChange = (e) => {
    setSelectedItems(e);
  };

  const handleCountChange = (e) => {
    e.persist();
    setQuantity(e.target.value);
  };

  const addToItems = () => {
    if (
      !includes(
        items.map((x) => x.id),
        selectedItems.value
      )
    ) {
      setItems([
        ...items,
        {
          id: selectedItems.value,
          name: selectedItems.name,
          quantity: quantity,
          measurement: selectedItems.measurement,
        },
      ]);
    }

    setQuantity(0);
    setSelectedItems({});
    handleClose();
  };

  const updateStock = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .post(`${process.env.REACT_APP_API_URL}/stock/updateMenuStockCount`, {
        place_stock_id: stockID.id,
        quantity: quantity,
        menu_id: localStorage.getItem("menuID"),
      })
      .then(() => {
        setStockId(null);
        setQuantity(0);
        loadMenuStocks();
        setShowEdit(false);
      })
      .catch((err) => console.log(err.response.data));
  };

  const handleSubmit = () => {
    setLoading(true);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .post(`${process.env.REACT_APP_API_URL}/stock/addMenuStockCount`, {
        place: localStorage.getItem("place"),
        stocks: items,
        menu_id: localStorage.getItem("menuID"),
      })
      .then(() => {
        setLoading(false);
        localStorage.removeItem("menuID");
        navigate("/invetoryrestaurant");
      })
      .catch(() => {
        console.log("error");
        setLoading(false);
      });
  };

  return (
    <>
      <NavigationBar />
      <div
        className="container-fluid"
        style={{
          padding: "2rem 0 0 0",
        }}
      >
        <div className="d-flex flex-row justify-content-start">
          <div
            className="bg-danger me-3 mt-2"
            style={{ width: "1rem", height: "1rem" }}
          >
            {" "}
          </div>
          <h4 style={{ fontWeight: "700" }}>Menu stock</h4>
          <button className="btn btn-danger ms-4" onClick={handleShow}>
            Add Stock item
          </button>
          <ReturnBackButton />
        </div>

        {items.length > 0 && (
          <div className="container mt-5">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Stock Item</th>
                  <th>Measurement</th>
                  <th>How many times per unit</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {items.map((t, i) => (
                  <tr key={t.id}>
                    <td>{i + 1}</td>
                    <td>{t.name}</td>
                    <td>{t.measurement}</td>
                    <td>{t.quantity}</td>
                    <td>
                      <FaPenAlt
                        onClick={() => {
                          setStockId(t);
                          setQuantity(t.quantity);
                          setShowEdit(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <div onClick={handleSubmit} className="btn btn-danger mt-5">
              {loading ? "Updating..." : "Update stock items list"}
            </div>
          </div>
        )}
      </div>

      <Modal
        show={showEdit}
        onHide={() => {
          setQuantity(0);
          setShowEdit(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Update quantity split into per unit for {stockID?.name}</p>
          <input
            className="form-control"
            placeholder="Quantity"
            type="number"
            onChange={handleCountChange}
            value={quantity}
          />
          <br />
          <div className="btn btn-warning" onClick={updateStock}>
            Update
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add stock item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Add new stock items for food item</p>

          <ReactSelect
            options={stocks}
            value={selectedItems}
            onChange={handleSelectedChange}
            placeholder="Select stock item"
          />
          <br />
          <input
            className="form-control"
            type="number"
            placeholder="Add Quantity per unit"
            onChange={handleCountChange}
          />
          <br />
          <div className="btn btn-danger" onClick={addToItems}>
            Submit
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
