import React, { useCallback, useEffect, useState } from "react";

import NavigationBar from "../../components/NavigationBar";
import DashboardCards from "./Cards";
import DashboardBarChart from "./DashboardBarChart";
import DashboardDoughnutChart from "./DashboardDoughnutChart";
import DashboardProgressBars from "./DashboardProgressBars";
import TopSelling from "./TopSelling";
import axios from "axios";
import OnboardingCheck from "./OnboardingCheck";
import DiscountPlace from "../../components/DiscountPlace";
import SalesBarChart from "../../components/charts/SalesBarChart";
import { Col, Container, Row } from "react-bootstrap";
import DashboardUploadMenuCSV from "./DashboardUploadMenuCSV";
import SalesChart from "../../components/charts/SalesChart";

export default function Dashboard() {
  const [orders, setOrders] = useState([]);
  const [menuItemsCount, setMenuItemsCount] = useState(0);
  const [sales, setSales] = useState([]);
  const [mostSoldItems, setMostSoldItems] = useState([]);
  const [types, setTypes] = useState([]);
  const [place, setPlace] = useState({});
  const [countables, setCountables] = useState({
    typesCount: 0,
    tablesCount: 0,
  });

  const loadData = useCallback(async () => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

    try {
      let res = await axios.get(`${process.env.REACT_APP_API_URL}/place/restaurantData/${localStorage.getItem("place")}`);

      console.log(res.data);
      setOrders(res.data.orders);
      setMenuItemsCount(res.data.menuItemsCount);
      setSales(res.data.orders.filter((x) => x.payment_status == true));
      setMostSoldItems(res.data.mostSold);
      setTypes(res.data.types);
      setPlace(res.data.place);
      setCountables({
        tablesCount: res.data.tablesCount,
        typesCount: res.data.typesCount,
      });
    } catch (error) {
      console.error(error);
    }
  });

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <NavigationBar />
      <Container
        fluid
        style={{
          background: "#f7f7f7",
          height: "100vh",
          padding: "2rem 0 0 0",
        }}
      >
        <div className="d-flex flex-row justify-content-start">
          <div className="bg-danger me-3 mt-2" style={{ width: "1rem", height: "1rem" }}>
            {" "}
          </div>
          <h4 style={{ fontWeight: "700" }}>Dashboard</h4>
        </div>
        <Container>
          <SalesChart />
          <Row className="g-3">
            <Col md={12}>
              {(countables.typesCount === 0 || countables.tablesCount === 0 || menuItemsCount === 0) && (
                <OnboardingCheck
                  typesCount={countables.typesCount}
                  menuItemsCount={menuItemsCount}
                  tablesCount={countables.tablesCount}
                />
              )}
            </Col>
            <Col md={12}>
              <SalesBarChart />
            </Col>
            <Col md={6}>
              <DashboardUploadMenuCSV />
              <DashboardCards orders={orders} sales={sales} menuCount={menuItemsCount} />
            </Col>
            <Col md={6}>
              <Row className="g-3">
                <Col md={12}>
                  <DiscountPlace place={place} loadData={loadData} />
                </Col>
                <Col md={12}>
                  <TopSelling mostSoldItems={mostSoldItems} />
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <DashboardProgressBars orders={orders} />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
