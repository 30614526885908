import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";

export default function DeletePastRequest({ id, loadData }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .get(`${process.env.REACT_APP_API_URL}/stock/request/delete/${id}`)
      .then(() => {
        handleClose();
        loadData();
        toast.success("Request deleted");
      })
      .catch(() => toast.error("An error occurred"));
  };

  return (
    <>
      <FaTrashAlt className="text-danger ms-4" onClick={handleShow} size={14} />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p>Are you sure you want to delete this request?</p>

            <div className="mt-3 btn btn-danger" onClick={handleDelete}>
              Delete
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
