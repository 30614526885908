import React, { useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { sumBy } from "lodash";
import PDFDocument from "./PDFDocument";

export default function ViewRequestItems({ items, title }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [values, setValues] = useState([]);
  const handleShow = () => {
    setValues(
      items.map((k, i) => ({
        id: i + 1,
        name: k.placestock.stock.item_name,
        quantity:
          title == "Confirmed items" ? k.quantity_confirmed : k.quantity,
        price:
          title == "Confirmed items"
            ? k.amount_confirmed.toLocaleString("en-US")
            : k.amount.toLocaleString("en-US"),
        total:
          title == "Confirmed items"
            ? (k.quantity_confirmed * k.amount_confirmed).toLocaleString(
                "en-US"
              )
            : (k.quantity * k.amount).toLocaleString("en-US"),
      }))
    );
    console.log(items);
    setShow(true);
  };

  const headers = [
    {
      key: "id",
      name: "ID",
    },
    {
      key: "name",
      name: "Name",
    },
    {
      key: "quantity",
      name: "Quantity",
    },
    {
      key: "price",
      name: "Price per unit",
    },
    {
      key: "total",
      name: "Total Price",
    },
  ];
  return (
    <>
      <FaEye className="text-danger ms-2" size={18} onClick={handleShow} />

      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-row justify-content-end mb-3">
            <PDFDocument headers={headers} data={values} />
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Quantity</th>
                <th>Price per unit</th>
                <th>Total price</th>
                <th>Note</th>
              </tr>
            </thead>
            <tbody>
              {items
                ?.map((k, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{k.placestock.stock.item_name}</td>
                    <td>
                      {title == "Confirmed items"
                        ? k.quantity_confirmed
                        : k.quantity}
                    </td>
                    <td>
                      {title == "Confirmed items"
                        ? k.amount_confirmed.toLocaleString("en-US")
                        : k.amount.toLocaleString("en-US")}
                    </td>
                    <td>
                      {title == "Confirmed items"
                        ? (
                            k.quantity_confirmed * k.amount_confirmed
                          ).toLocaleString("en-US")
                        : (k.quantity * k.amount).toLocaleString("en-US")}
                    </td>
                    <td>{k.note}</td>
                  </tr>
                ))}
              <tr>
                <td colSpan={6}></td>
              </tr>
              <tr>
                <td colSpan={6}></td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b>Total Quantity</b>
                </td>
                <td>
                  {title == "Confirmed items"
                    ? sumBy(items, "quantity_confirmed")
                    : sumBy(items, "quantity")}
                </td>
                <td colSpan={3}></td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <b>Total price</b>
                </td>
                <td>
                  {title == "Confirmed items"
                    ? sumBy(
                        items,
                        (x) => x.quantity_confirmed * x.amount_confirmed
                      )
                    : sumBy(items, (x) => x.quantity * x.amount)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
}
