import React, { useEffect, useState } from "react";
import { CiCircleInfo } from "react-icons/ci";
import { ProgressBar, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import Pusher from 'pusher-js';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import sampleFilePath from "../../assets/docs/MenuDataSample.csv";

export default function DashboardUploadMenuCSV() {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file");
      return;
    }

    const placeId = localStorage.getItem("place");

    const formData = new FormData();
    formData.append("file", file);
    formData.append("place_id", placeId);

    try {
      setUploading(true);

      const response = await axios.post(
        "https://api.tayari.co.tz/api/v1/menu/csv-store",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
            setUploadProgress(progress);
          },
        }
      );

      if (response.status === 200) {
        setFile(null);
        setUploadProgress(0);
      } else {
        throw new Error("Failed to upload file");
      }
    } catch (error) {
      // console.error("Upload error:", error);
      toast.error("Failed to upload file. Please try again.");
    } finally {
      setUploading(false);
    }
  };

  const downloadSample = () => {
    const anchor = document.createElement("a");
    anchor.href = sampleFilePath;
    anchor.download = "MenuDataSample.csv";
    anchor.click();
  };

  useEffect(() => {
    const pusher = new Pusher('973a530b36678d59298c', {
      cluster: 'mt1',
    });

    const channel = pusher.subscribe('menu-import-status-channel');

    channel.bind('App\\Events\\MenuImportStatusUpdate', function (data) {
      // console.log('Received event:', data);
      if (data.message === "Success Import") {
        toast.success("Menu csv file uploaded and imported successfull");
      } else {
        toast.error(`Menu csv file failed to import`);
      }
    });

    return () => {
      channel.unbind();
      pusher.unsubscribe('menu-import-status-channel');
    };
  }, []);

  const infoDescription = `Tafadhali upload file lenye Menu ya mgahawa wako likiwa na taarifa zilizopangwa na ukifuata mtiririko kama hionekanavyo kwenye sample document,\n
  Baada ya hapo upload file hili kwenye fomati ya CSV kupitia Microsoft Excel.`;

  return (
    <div className="card p-2 mb-2" style={{ width: "100%", background: "white" }}>
      <div className="mb-3">
        <div>
          <label htmlFor="menuFile" className="form-label">
            <div className="flex w-full gap-5 justify-between items-center">
              Upload CSV Menu File
              <div
                className="info-icon-container"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <CiCircleInfo size={20} className="info-icon" />
                {showTooltip && (
                  <div className="info-tooltip">
                    <p>{infoDescription}</p>
                  </div>
                )}
              </div>
            </div>
          </label>
        </div>
        <input
          className="form-control"
          type="file"
          id="menuFile"
          onChange={handleFileChange}
          accept=".csv"
        />
      </div>
      {file && (
        <div className="mb-3">
          <Button variant="primary" onClick={handleUpload}>
            {uploading ? (
              <span>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Uploading...
              </span>
            ) : (
              "Upload"
            )}
          </Button>
        </div>
      )}
      {uploadProgress > 0 && (
        <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
      )}
      {!file && !uploadProgress && (
        <div>
          <Button variant="info" onClick={downloadSample} className="text-white">
            Download Sample
          </Button>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
