import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Badge, Col, Modal, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Bars } from "react-loader-spinner";
import AddConfiguration from "../../components/AddConfiguration";
import EditConfiguration from "../../components/EditConfiguration";
import NavigationBar from "../../components/NavigationBar";
import ReturnBackButton from "../../components/ReturnBackButton";

export default function Configuraiton() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [measurements, setMeasurements] = useState([]);

  const STORY_HEADERS = [
    {
      prop: "item_name",
      title: "Name",
      isFilterable: true,
      cell: (row) => row.item_name,
    },
    {
      prop: "place_id",
      title: "Measurement",
      cell: (row) => row.measurement.name,
    },
    {
      prop: "created_at",
      title: "Created",
      cell: (row) => moment(row.created_at).format("DD-MM-YYYY"),
    },
    {
      prop: "updated_at",
      title: "Actions",
      cell: (row) => (
        <div className="d-flex flex-row justify-content-end">
          <EditConfiguration
            item={row}
            loadItems={loadItems}
            measurements={measurements}
          />
        </div>
      ),
    },
  ];

  const loadItems = useCallback(async () => {
    let t = await axios.get(
      `${process.env.REACT_APP_API_URL}/stock/stockitems/${localStorage.getItem(
        "place"
      )}`
    );
    setItems(t.data);
  });

  useEffect(() => {
    setLoading(true);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .get(`${process.env.REACT_APP_API_URL}/stock/measurements/get`)
      .then((res) => {
        setMeasurements(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/stock/stockitems/${localStorage.getItem("place")}`
      )
      .then((res) => {
        setLoading(false);
        setItems(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <NavigationBar />
      <div
        className="container-fluid"
        style={{
          padding: "2rem 0 0 0",
        }}
      >
        <div className="d-flex flex-row justify-content-start">
          <div
            className="bg-danger me-3 mt-2"
            style={{ width: "1rem", height: "1rem" }}
          >
            {" "}
          </div>
          <h4 style={{ fontWeight: "700" }}>Configuration &nbsp; </h4>
          <AddConfiguration measurements={measurements} loadItems={loadItems} />
          <ReturnBackButton />
        </div>
        <div className="container pt-4">
          {loading ? (
            <Bars
              heigth="100"
              width="1400"
              color="red"
              ariaLabel="loading-indicator"
            />
          ) : (
            <DatatableWrapper
              body={items}
              headers={STORY_HEADERS}
              paginationOptionsProps={{
                initialState: {
                  rowsPerPage: 10,
                  options: [5, 10, 15, 20],
                },
              }}
            >
              <Row className="mb-4 p-2">
                <Col
                  xs={12}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Filter classes={{ clearButton: "btn-danger" }} />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                >
                  <PaginationOptions alwaysShowPagination="true" />
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Pagination classes={{ button: "btn-danger" }} />
                </Col>
              </Row>
              <Table>
                <TableHeader />
                <TableBody />
              </Table>
            </DatatableWrapper>
          )}
        </div>
      </div>
    </>
  );
}
