import React from "react";
import InventoryCard from "../../components/InventoryCard";
import NavigationBar from "../../components/NavigationBar";
import { ReactComponent as FoodStockSvg } from "../../assets/images/inventoryicons/foodstock.svg";
import { ReactComponent as IngredientsSvg } from "../../assets/images/inventoryicons/ingredients.svg";
import { ReactComponent as ConfigurationSvg } from "../../assets/images/inventoryicons/configuration.svg";
import { ReactComponent as DrinkSvg } from "../../assets/images/inventoryicons/drink.svg";
import { ReactComponent as RestaurantSvg } from "../../assets/images/inventoryicons/restaurant.svg";
import { ReactComponent as InformationSvg } from "../../assets/images/inventoryicons/information.svg";
import { ReactComponent as CateringSvg } from "../../assets/images/inventoryicons/catering.svg";

export default function InventoryHome() {
  return (
    <>
      <NavigationBar />
      <div
        className="container-fluid"
        style={{
          background: "#f7f7f7",
          height: "100vh",
          padding: "2rem 0 0 0",
        }}
      >
        <div className="d-flex flex-row justify-content-start">
          <div
            className="bg-danger me-3 mt-2"
            style={{ width: "1rem", height: "1rem" }}
          >
            {" "}
          </div>
          <h4 style={{ fontWeight: "700" }}>Inventory Management</h4>
        </div>

        <div className="container">
          <h4 className="mt-5">Assets</h4>

          <div className="row">
            <InventoryCard title="Food Stock" link="/inventory">
              <FoodStockSvg
                height={24}
                width={24}
                style={{ margin: "0.7rem 0rem 0 0.8rem" }}
                fill="white"
              />
            </InventoryCard>
{/* 
            <InventoryCard title="Ingredients" link="">
              <IngredientsSvg
                height={24}
                width={24}
                style={{ margin: "0.7rem 0rem 0 0.8rem" }}
                fill="white"
              />
            </InventoryCard> */}

            <InventoryCard title="Configuration" link="/configuration">
              <ConfigurationSvg
                height={24}
                width={24}
                style={{ margin: "0.7rem 0rem 0 0.8rem" }}
                fill="white"
              />
            </InventoryCard>
          </div>

          <h4 className="mt-5">Departments</h4>
          <div className="row">
            <InventoryCard title="Drinks" link="/drinks">
              <DrinkSvg
                height={24}
                width={24}
                style={{ margin: "0.7rem 0rem 0 0.8rem" }}
                fill="white"
              />
            </InventoryCard>

            <InventoryCard title="Restaurant" link="/invetoryrestaurant">
              <RestaurantSvg
                height={24}
                width={24}
                style={{ margin: "0.7rem 0rem 0 0.8rem" }}
                fill="white"
              />
            </InventoryCard>

            <InventoryCard title="Reports" link="/reports">
              <InformationSvg
                height={24}
                width={24}
                style={{ margin: "0.7rem 0rem 0 0.8rem" }}
                fill="white"
              />
            </InventoryCard>

            {/* <InventoryCard title="Catering" link="">
              <CateringSvg
                height={24}
                width={24}
                style={{ margin: "0.7rem 0rem 0 0.8rem" }}
                fill="white"
              />
            </InventoryCard> */}

          </div>
        </div>
      </div>
    </>
  );
}
