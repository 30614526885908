import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/tayarilogo2.png";
import Drawer from "./Drawer";
import { ReactComponent as HomeSvg } from "../assets/svg/home.svg";
import { ReactComponent as TransactionSvg } from "../assets/svg/chart-histogram.svg";
import { ReactComponent as UserSvg } from "../assets/svg/user.svg";
import { ReactComponent as AppsSvg } from "../assets/svg/apps.svg";
import { ReactComponent as InventorySvg } from "../assets/images/inventoryicons/Inventory.svg";

export default function NavigationBar() {
  const navStyle = {
    color: "#bcbdbe",
  };

  const navigate = useNavigate();

  const reroute = (link) => {
    localStorage.setItem("highlighted", link);
    navigate(link);
  };

  return (
    <Navbar style={{ background: "white" }} expand="lg" sticky="top">
      <Container>
        {localStorage.getItem("role") == 4 ? (
          <Navbar.Brand onClick={() => reroute("/dashboard")}>
            <img src={logo} alt="logo" style={{ maxWidth: "100px" }} />
          </Navbar.Brand>
        ) : (
          <Navbar.Brand onClick={() => reroute("/orderstatus")}>
            <img src={logo} alt="logo" style={{ maxWidth: "100px" }} />
          </Navbar.Brand>
        )}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">

            {
              localStorage.getItem('role') == 4 &&
              <Nav.Link onClick={() => reroute("/home")} style={navStyle}>
                <div className="d-flex flex-row justify-content-between">
                  <HomeSvg
                    height={24}
                    width={24}
                    className="mt-2"
                    fill={
                      localStorage.getItem("highlighted") == "/home"
                        ? "red"
                        : "#bcbdbe"
                    }
                  />{" "}
                  <p
                    style={{
                      margin: "9px 5px 0 10px",
                      color: `${localStorage.getItem("highlighted") == "/home"
                        ? "red"
                        : "#bcbdbe"
                        }`,
                    }}
                  >
                    POS
                  </p>
                </div>
              </Nav.Link>
            }

            {/* {localStorage.getItem("role") == 4 && ( */}
            <Nav.Link
              onClick={() => reroute("/transactions")}
              style={navStyle}
            >
              <div className="d-flex flex-row justify-content-between">
                <TransactionSvg
                  height={24}
                  width={24}
                  className="mt-2"
                  fill={
                    localStorage.getItem("highlighted") == "/transactions"
                      ? "red"
                      : "#bcbdbe"
                  }
                />{" "}
                <p
                  style={{
                    margin: "9px 5px 0 10px",
                    color: `${localStorage.getItem("highlighted") == "/transactions"
                      ? "red"
                      : "#bcbdbe"
                      }`,
                  }}
                >
                  Transactions
                </p>
              </div>
            </Nav.Link>
            {/* )} */}

            {/* {localStorage.getItem("role") == 4 && ( */}
            <Nav.Link
              onClick={() => reroute("/orderstatus")}
              style={navStyle}
            >
              <div className="d-flex flex-row justify-content-between">
                <AppsSvg
                  height={24}
                  width={24}
                  className="mt-2"
                  fill={
                    localStorage.getItem("highlighted") == "/orderstatus"
                      ? "red"
                      : "#bcbdbe"
                  }
                />{" "}
                <p
                  style={{
                    margin: "9px 5px 0 10px",
                    color: `${localStorage.getItem("highlighted") == "/orderstatus"
                      ? "red"
                      : "#bcbdbe"
                      }`,
                  }}
                >
                  Order Status
                </p>
              </div>
            </Nav.Link>
            {/* )} */}

            {localStorage.getItem("role") == 4 && (
              <Nav.Link
                onClick={() => reroute("/inventoryhome")}
                style={navStyle}
              >
                <div className="d-flex flex-row justify-content-between">
                  <InventorySvg
                    height={24}
                    width={24}
                    className="mt-2"
                    fill={
                      localStorage.getItem("highlighted") == "/inventoryhome"
                        ? "red"
                        : "#bcbdbe"
                    }
                  />{" "}
                  <p
                    style={{
                      margin: "9px 5px 0 10px",
                      color: `${localStorage.getItem("highlighted") == "/inventoryhome"
                        ? "red"
                        : "#bcbdbe"
                        }`,
                    }}
                  >
                    Inventory
                  </p>
                </div>
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
        <div>
          <Drawer />
        </div>
      </Container>
    </Navbar>
  );
}
