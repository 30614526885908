import React, { useState } from "react";
import bg from "../../assets/images/newloginimage.jpg";
import logo from "../../assets/images/logo2.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons

export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const inputStyle = {
    height: "4rem",
  };

  const buttonStyle = {
    fontWeight: "800",
    height: "3rem",
  };

  const [values, setValues] = useState({
    email: "",
    password: "",
    phone: "",
    otp: "",
  });

  const handleEmailChange = (e) => {
    e.persist();
    setValues({
      ...values,
      email: e.target.value,
    });
  };

  const handleOTPChange = (e) => {
    e.persist();
    setValues({
      ...values,
      otp: e.target.value,
    });
  };

  const handlePasswordChange = (e) => {
    e.persist();
    setValues({
      ...values,
      password: e.target.value,
    });
  };

  const navigate = useNavigate();

  const handleOTPSubmit = () => {
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/newowner/otp`, {
        otp: values.otp,
        phone: values.phone,
      })
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data.user));
        localStorage.setItem("token", res.data.token);
        handleClose();
        setLoading(false);

        localStorage.setItem("role", res.data.employee.role);

        if (res.data.employee.role == 4) {
          localStorage.setItem("highlighted", "/dashboard");
          navigate("/places");
        } else {
          localStorage.setItem("place", res.data.employee.place.id);
          localStorage.setItem("placeimage", res.data.employee.place.logo_url);
          localStorage.setItem("highlighted", "/orderstatus");
          navigate("/orderstatus");
        }
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  };

  function padFunction(number) {
    let string = String(number);
    let sliced = string.slice(-3);
    let mask = String(sliced).padStart(string.length, "*");
    return mask;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/newowner/login`, values)
      .then((res) => {
        setLoading(false);
        setValues({
          ...values,
          phone: res.data.phone,
        });

        if (res.data.has_otp == 1) {
          handleShow();
        } else {
          localStorage.setItem("user", JSON.stringify(res.data.user));
          localStorage.setItem("token", res.data.token);
          setLoading(false);
          localStorage.setItem("role", res.data.employee.role);

          if (res.data.employee.role == 4) {
            localStorage.setItem("highlighted", "/dashboard");
            navigate("/places");
          } else {
            localStorage.setItem("place", res.data.employee.place.id);
            localStorage.setItem(
              "placeimage",
              res.data.employee.place.logo_url
            );
            localStorage.setItem("highlighted", "/orderstatus");
            navigate("/orderstatus");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data);
      });
  };

  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ height: "100vh" }}>
          <div
            className="col-md-6 text-white text-center d-flex flex-column justify-content-end"
            style={{
              backgroundImage: `url(${bg})`,
              boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.3)",
              backgroundSize: "cover",
              backgroundPosition: "90% center",
              backgroundRepeat: "no-repeat",
              fontWeight: "700",
            }}
          >
            <div style={{ paddingBottom: "5rem" }}>
              <img src={logo} style={{ maxWidth: "20rem" }} alt="logo" />
              <div style={{ marginTop: "-6rem" }}>
                <h4>One - stop for all restaurant's ordering</h4>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 p-5 text-white"
            style={{ background: "#214071" }}
          >
            <h2>Receive and Manage orders</h2>
            <p>
              Tayari Restaurant Dashboard gives you the flexibility, visibility
              and customer insights you need to connect with more customers.
            </p>
            <br />
            <h3>Please sign in to continue</h3>

            <form className="mt-5" onSubmit={handleSubmit}>
              <div className="form-group mb-4">
                <input
                  className="form-control"
                  placeholder="Email"
                  type="email"
                  style={inputStyle}
                  onChange={handleEmailChange}
                />
              </div>
              <div className="form-group mb-4 position-relative">
                <input
                  className="form-control"
                  type={showPassword ? "text" : "password"}
                  style={inputStyle}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                />
                <span
                  onClick={toggleShowPassword}
                  style={{
                    position: "absolute",
                    right: "1rem",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? <FaEyeSlash color='black' /> : <FaEye color='black' />}
                </span>
              </div>
              <div className="form-group mb-2 d-grid">
                <button
                  type="submit"
                  className="btn btn-danger text-white"
                  style={buttonStyle}
                >
                  {loading ? "Signing in..." : "Sign in"}
                </button>
              </div>
            </form>

            <div className="mt-5 text-center">
              <p>
                <Link
                  to="/forgot"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  Forgot Password?
                </Link>
                <br />
                <Link to="/register" style={{ color: "white" }}>
                  Don't have an account? Go to registration
                </Link>
              </p>
            </div>

            <div className="mt-5 text-center">
              <p>
                By continuing you are indicating that you accept our
                <br />
                <a
                  href="https://tayari.co.tz/privacy"
                  style={{ textDecoration: "none", color: "red" }}
                  target="_blank"
                >
                  Terms of service & privacy policy
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p>Enter the OTP sent to {padFunction(values.phone)}</p>

            <input
              className="form-control my-2"
              value={values.otp}
              onChange={handleOTPChange}
            />

            <button onClick={handleOTPSubmit} className="btn btn-danger">
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}