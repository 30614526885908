import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaPen } from "react-icons/fa";
import { toast } from "react-toastify";

export default function EditConfiguration({ item, measurements, loadItems }) {
  const [values, setValues] = useState({
    name: "",
    measurement: "",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    console.log(item);
    setValues({
      ...values,
      name: item.item_name,
      measurement: item.measurement_id,
    });
  };
  const [loading, setLoading] = useState(false);

  const handleNameChange = (e) => {
    e.persist();
    setValues({
      ...values,
      name: e.target.value,
    });
  };

  const handleMeasurementChange = (e) => {
    e.persist();
    setValues({
      ...values,
      measurement: e.target.value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .post(`${process.env.REACT_APP_API_URL}/stock/stockitem/update`, {
        measurement: values.measurement,
        name: values.name,
        id: item.id,
      })
      .then((res) => {
        setLoading(false);
        toast.success(res.data);
        handleClose();
        loadItems();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err.response.data);
      });
  };

  return (
    <>
      <FaPen className="text-danger" size={12} onClick={handleShow} />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit stock item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-3">
            <label>Name</label>
            <input
              className="form-control"
              value={values.name}
              onChange={handleNameChange}
            />
          </div>
          <div className="form-group mb-4">
            <label>Measurement</label>
            <select
              className="form-control"
              value={values.measurement}
              onChange={handleMeasurementChange}
            >
              {measurements.map((m) => (
                <option key={m.id} value={m.id}>
                  {m.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <div className="btn btn-danger" onClick={handleSubmit}>
              {loading ? "Updating..." : "Update"}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
