import React, { useEffect, useState } from "react";
import NavigationBar from "../components/NavigationBar";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Col, Row, Table, Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import moment from "moment";
import { Bars } from "react-loader-spinner";

export default function Customers() {
  const STORY_HEADERS = [
    {
      prop: "name",
      title: "Name",
      isFilterable: true,
    },
    {
      prop: "phone",
      title: "Phone number",
    },
    {
      prop: "email",
      title: "Email Address",
    },
    {
      prop: "created_at",
      title: "Created",
      cell: (row) => moment(row.created_at).format("LLL"),
    },
    {
      prop: "actions",
      title: "Actions",
      cell: (row) => (
        <button
          onClick={() => handleShowOrders(row.id)}
          className="cursor-pointer border text-center p-1 rounded hover:text-blue-500"
        >
          {loadingCustomerId === row.id ? "Opening..." : "View Orders"}
        </button>
      ),
    },
  ];

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCustomerId, setLoadingCustomerId] = useState(null);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedCustomerName, setSelectedCustomerName] = useState(null);
  const [selectedCustomerOrders, setSelectedCustomerOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [userOrderCount, setUserOrderCount] = useState(0);
  const [userFavoriteMeal, setUserFavoriteMeal] = useState(null);
  const [userOrderFrequency, setUserOrderFrequency] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/customers/place/${localStorage.getItem("place")}`
        );
        const reversedCustomers = response.data.reverse();
        setCustomers(reversedCustomers);
        setLoading(false);
      } catch (err) {
        console.error("Failed to fetch customers:", err);
        setLoading(false);
      }
    };

    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/menu/place/${localStorage.getItem("place")}`
        );
        setMenuItems(response.data);
      } catch (err) {
        console.error("Failed to fetch menu items:", err);
      }
    };

    fetchCustomers();
    fetchMenuItems();
  }, []);

  const handleShowOrders = async (customerId) => {
    setLoadingCustomerId(customerId);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/user-orders/${customerId}`
      );
  
      // Filter orders to include only those from your restaurant
      const ordersFromMyRestaurant = response.data.orders.data.filter(order => order.place_id.toString() === localStorage.getItem("place"));
  
      setSelectedCustomerId(response.data.user_id);
      setSelectedCustomerName(response.data.user_name);
      setSelectedCustomerOrders(ordersFromMyRestaurant);
      analyzeUserOrderHistory(ordersFromMyRestaurant);
  
      const orderDetailsPromises = ordersFromMyRestaurant.map((order) =>
        axios.get(`${process.env.REACT_APP_API_URL}/order-details/${order.order_number}`)
      );
  
      const orderDetailsResponses = await Promise.all(orderDetailsPromises);
      const orderDetailsData = orderDetailsResponses.map((response) => response.data);
      setOrderDetails(orderDetailsData);
      // console.log(orderDetailsData);
  
      setShowModal(true);
    } catch (err) {
      console.error("Failed to fetch orders:", err);
    } finally {
      setLoadingCustomerId(null);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const analyzeUserOrderHistory = (orders) => {
    try {
      setUserOrderCount(orders.length);

      // Calculate order frequency ranges
      const timestamps = orders.map((order) => moment(order.created_at));
      const timeDifferences = [];
      for (let i = 1; i < timestamps.length; i++) {
        const diff = timestamps[i].diff(timestamps[i - 1], "hours");
        timeDifferences.push(diff);
      }

      // Group time differences into ranges
      const timeRanges = {
        "0-12 hours": 0,
        "12-24 hours": 0,
        "1-2 days": 0,
        "2-3 days": 0,
        "3-7 days": 0,
        "1 week+": 0,
      };

      timeDifferences.forEach((diff) => {
        if (diff <= 12) {
          timeRanges["0-12 hours"]++;
        } else if (diff <= 24) {
          timeRanges["12-24 hours"]++;
        } else if (diff <= 48) {
          timeRanges["1-2 days"]++;
        } else if (diff <= 72) {
          timeRanges["2-3 days"]++;
        } else if (diff <= 168) {
          timeRanges["3-7 days"]++;
        } else {
          timeRanges["1 week+"]++;
        }
      });

      const mostCommonTimeRange = Object.keys(timeRanges).reduce((a, b) =>
        timeRanges[a] > timeRanges[b] ? a : b
      );
      setUserOrderFrequency(mostCommonTimeRange);

      // Calculate favorite meal
      const mealFrequency = {};
      orders.forEach((order) => {
        order.food.forEach((item) => {
          mealFrequency[item.id] = (mealFrequency[item.id] || 0) + 1;
        });
        order.drinks.forEach((item) => {
          mealFrequency[item.id] = (mealFrequency[item.id] || 0) + 1;
        });
      });

      let maxFrequency = 0;
      let favoriteMealId = null;
      Object.keys(mealFrequency).forEach((id) => {
        if (mealFrequency[id] > maxFrequency) {
          maxFrequency = mealFrequency[id];
          favoriteMealId = id;
        }
      });
      const favoriteMeal = menuItems.find((menuItem) => menuItem.id === parseInt(favoriteMealId));
      setUserFavoriteMeal(favoriteMeal ? favoriteMeal.menu_name : "N/A");
    } catch (err) {
      console.error("Failed to analyze user order history:", err);
    }
  };

  const recommendMenuItems = () => {
    if (!userFavoriteMeal || !menuItems.length) return [];

    return menuItems.filter((menuItem) =>
      menuItem.menu_name.toLowerCase().includes(userFavoriteMeal.toLowerCase())
    );
  };

  const recommendedItems = recommendMenuItems();

  return (
    <>
      <NavigationBar />
      <div className="container-fluid" style={{ background: "#f7f7f7", height: "100vh", padding: "2rem 0 0 0" }}>
        <div className="d-flex flex-row justify-content-start">
          <div className="bg-danger me-3 mt-2" style={{ width: "1rem", height: "1rem" }}> </div>
          <h4 style={{ fontWeight: "700" }}>Customers</h4>
        </div>
        <div className="container">
          <div className="mt-3">
            {loading ? (
              <Bars height="100" width="1400" color="red" ariaLabel="loading-indicator" />
            ) : (
              <DatatableWrapper
                body={customers}
                headers={STORY_HEADERS}
                paginationOptionsProps={{
                  initialState: { rowsPerPage: 10, options: [5, 10, 15, 20] },
                }}
                paginationProps={{
                  currentPage,
                  onPageChange: (page) => setCurrentPage(page),
                }}
              >
                <Row className="mb-4 p-2">
                  <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Filter classes={{ clearButton: "btn-danger" }} />
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                  >
                    <PaginationOptions alwaysShowPagination="true" />
                  </Col>
                  <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                    <Pagination classes={{ button: "btn-danger" }} />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>
            )}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>{selectedCustomerName}'s Order History</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {orderDetails && (
                  <div>
                    <ul>
                      {orderDetails.map((order) => (
                        <li key={order.data.id}>
                          <div>
                            <h5>Order Number: {order.data.order_number}</h5>
                            <p>Total Cost: {order.data.total_cost}</p>
                            <h6>Food Items:</h6>
                            {order.data.food && order.data.food.length > 0 ? (
                              order.data.food.map((foodItem) => (
                                <div key={foodItem.id} className="border-b py-2 flex justify-start items-center min-w-[200px]">
                                  <img
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      flexShrink: 0,
                                      objectFit: "cover",
                                      borderRadius: "12px",
                                      margin: "8px",
                                    }}
                                    src={`https://api.tayari.co.tz/images/food/${foodItem.banner}`}
                                    alt="Food Item"
                                  />
                                  <div>
                                    <div className="font-medium">{foodItem.menu_name}</div>
                                    <div>Size: {foodItem.size}</div>
                                    <div>Quantity: {foodItem.pivot.quantity}</div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div>No food items ordered.</div>
                            )}
                            <h6>Drink Items:</h6>
                            {order.data.drinks && order.data.drinks.length > 0 ? (
                              order.data.drinks.map((drinkItem) => (
                                <div key={drinkItem.id} className="border-b py-2 flex justify-start items-center min-w-[200px]">
                                  <img
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      flexShrink: 0,
                                      objectFit: "cover",
                                      borderRadius: "12px",
                                      margin: "8px",
                                    }}
                                    src={`https://api.tayari.co.tz/images/drinks/${drinkItem.image}`}
                                    alt="Drink Item"
                                  />
                                  <div>
                                    <div className="font-medium">{drinkItem.name}</div>
                                    <div>Size: {drinkItem.size}</div>
                                    <div>Quantity: {drinkItem.pivot.quantity}</div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div>No drink items ordered.</div>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div>
                      <h5>Analysis of {selectedCustomerName}'s Order History</h5>
                      <p>Total Orders: {userOrderCount}</p>
                      <p>Average Order Frequency: {userOrderFrequency}</p>
                      {/* {userFavoriteMeal && <p>Favorite Meal: {userFavoriteMeal || "unknown"}</p>} */}
                    </div>
                    {recommendedItems.length > 0 && (
                      <div>
                        <h5>Recommended Menu Items for {selectedCustomerName}</h5>
                        <ul>
                          {recommendedItems.map((item) => (
                            <li key={item.id}>
                              {item.menu_name} - {item.price}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}
