import React from "react";
import { useNavigate } from "react-router-dom";

export default function InventoryCard({ title, link, children }) {
  const router = useNavigate();

  return (
    <div className="col-md-3 mb-3">
      <div
        onClick={() => router(link)}
        style={{ cursor: "pointer" }}
        className="d-flex shadow flex-row bg-white justify-content-start px-4 py-3 align-items-center"
      >
        <div
          className="bg-danger rounded-circle"
          style={{ height: "3rem", width: "3rem" }}
        >
          {children}
        </div>
        <p className="ps-4 mt-3">{title}</p>
      </div>
    </div>
  );
}
