import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";

export default function ConfirmRequest({ id, loadData, items }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [data, setData] = useState([])
  const handleShow = () => {
    setData([...items.map(x => ({
      ...x, 
      confirmedprice: 0,
      confirmedquantity: 0,
      note: ""
    }))])
    setShow(true)
  };

  const handlePriceOnChange = (e,id) => {
    e.persist()
    let content = [...data]
    let newIndex = content.findIndex(x => x.id == id)

    content[newIndex].confirmedprice = e.target.value

    setData([...content])
  }

  const handleQuantityOnChange = (e,id) => {
    e.persist()
    let content = [...data]
    let newIndex = content.findIndex(x => x.id == id)

    content[newIndex].confirmedquantity = e.target.value

    setData([...content])
  }

  const handleNoteOnChange = (e,id) => {
    e.persist()
    let content = [...data]
    let newIndex = content.findIndex(x => x.id == id)

    content[newIndex].note = e.target.value

    setData([...content])
  }

  const handleConfirm = () => {

    console.log(data);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .post(`${process.env.REACT_APP_API_URL}/stock/request/update/${id}`, {
        data: data
      })
      .then((res) => {
        handleClose();
        loadData();
        toast.success(res.data);
      })
      .catch((err) => console.log(err.response.data));
  };

  return (
    <>
      <FaCheck onClick={handleShow} className="text-danger me-3" />

      <Modal show={show} onHide={handleClose} centered fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>Confirming request</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="my-3">Are you sure you want to confirm this request?</p>

          <div className="pt-5">
            <div className="row">
              <div className="col-md-2">
                <b>Name</b>
              </div>
              <div className="col-md-2">
                <b>Price</b>
              </div>
              <div className="col-md-2">
                <b>Quantity</b>
              </div>
              <div className="col-md-2">
                <b>Confirmed price</b>
              </div>
              <div className="col-md-2">
                <b>Confirmed Quantity</b>
              </div>
              <div className="col-md-2">
                <b>Note</b>
              </div>
            </div>
            <hr />
            {data?.map((x) => (
              <div key={x.id} className="row mb-3">
                <div className="col-md-2">{x.placestock.stock.item_name}</div>
                <div className="col-md-2">{x.amount}</div>
                <div className="col-md-2">{x.quantity}</div>
                <div className="col-md-2">
                  <input className="form-control" onChange={e => handlePriceOnChange(e, x.id)}  />
                </div>
                <div className="col-md-2">
                  <input className="form-control" onChange={e => handleQuantityOnChange(e, x.id)} />
                </div>
                <div className="col-md-2">
                  <input className="form-control" onChange={e => handleNoteOnChange(e, x.id)} />
                </div>
              </div>
            ))}
          </div>

          <div className="btn btn-danger mt-4" onClick={handleConfirm}>
            Confirm
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
