import React, { useCallback, useEffect, useState } from "react";
import {
  DatatableWrapper,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Badge, Col, Row, Table } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import NavigationBar from "../../components/NavigationBar";
import ConfirmRequest from "../../components/ConfirmRequest";
import ViewRequestItems from "../../components/ViewRequestItems";
import DeletePastRequest from "./DeletePastRequest";
import ReturnBackButton from "../../components/ReturnBackButton";
import ManagerConfirmRequest from "../../components/ManagerConfirmRequest";

export default function PastRequests() {
  const STORY_HEADERS = [
    {
      prop: "created_at",
      title: "Request date",
      cell: (row) => moment(row.created_at).format("DD-MM-YYYY hh:mm a"),
    },
    {
      prop: "id",
      title: "Requester",
      cell: (row) => row.user.name,
    },
    {
      prop: "items",
      title: "Quantity",
      cell: (row) => row.items.length,
    },
    {
      prop: "recieved",
      title: "Status",
      cell: (row) =>
        row.recieved > 0 ? (
          <Badge bg="success">Delivered</Badge>
        ) : (
          <Badge bg="danger">Not Delivered</Badge>
        ),
    },
    {
      prop: 'confirm_code',
      title:'Management response',
      cell: row => row.confirm_code == null ? (
        <Badge bg="danger">Not Approved</Badge>
      ) : (
        <Badge bg="success">Approved</Badge>
      ),
    },
    {
        prop: "type",
        title: "Action",
        cell: row => <div className="d-flex flex-row justify-content-end">
          {
            row.recieved == 0 && 
            row.confirm_code == null && 
            localStorage.getItem('role') == 4 &&
            <ManagerConfirmRequest items={row.items} id={row.id} loadData={loadData} />
          }
            {
                row.recieved == 0 && row.confirm_code !== null && <ConfirmRequest items={row.items} id={row.id} loadData={loadData} />
            }
            <ViewRequestItems items={row.items} title="Requested items" />
            {
              row.recieved ==  0 && <DeletePastRequest loadData={loadData} id={row.id} />
            }
        </div>
    }
  ];

  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    let t = await axios.get(
      `${process.env.REACT_APP_API_URL}/stock/request/${localStorage.getItem(
        "place"
      )}`
    );
    console.log(t.data);
    setRequests(t.data);
  });

  useEffect(() => {
    document.body.style.backgroundColor = "#f7f7f7";
    setLoading(true);
    loadData().then(() => setLoading(false));
  }, []);

  return (
    <>
      <NavigationBar />
      <div
        className="container-fluid"
        style={{
          padding: "2rem 0 0 0",
        }}
      >
        <div className="d-flex flex-row justify-content-start">
          <div
            className="bg-danger me-3 mt-2"
            style={{ width: "1rem", height: "1rem" }}
          >
            {" "}
          </div>
          <h4 style={{ fontWeight: "700" }}>Requisitions</h4>
          <ReturnBackButton />
        </div>
        <div className="container">
          <div className="mt-3">
            {loading ? (
              <Bars
                heigth="100"
                width="1400"
                color="red"
                ariaLabel="loading-indicator"
              />
            ) : (
              <DatatableWrapper
                body={requests}
                headers={STORY_HEADERS}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 10,
                    options: [5, 10, 15, 20],
                  },
                }}
              >
                <Row className="mb-4 p-2">
                  <Col
                    xs={12}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                  ></Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                  >
                    <PaginationOptions alwaysShowPagination="true" />
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                  >
                    <Pagination classes={{ button: "btn-danger" }} />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
