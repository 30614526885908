import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";

export default function DeleteOrderItem({
  meal,
  order,
  setOrder,
  loadTransactions,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");

  const handleNoteChange = (e) => {
    e.persist();
    setNote(e.target.value);
  };

  const handlesSubmit = () => {
    setLoading(true);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .post(`${process.env.REACT_APP_API_URL}/order/delete/orderitem`, {
        order_id: order.id,
        menu_id: meal.id,
        note: note,
      })
      .then(() => {
        setOrder({
          ...order,
          cost: order.cost - meal.pivot.cost,
          total_cost: order.cost - meal.pivot.cost,
          food: order.food.filter((x) => x.id !== meal.id),
        });

        setLoading(false);
        handleClose();
        loadTransactions();
      })
      .catch((err) => {
        setLoading(false);
        handleClose();
        toast.error("An error has occured");
      });
  };

  return (
    <>
      <FaTrashAlt
        size={13}
        className="mb-1 text-danger"
        style={{ cursor: "pointer" }}
        onClick={handleShow}
      />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete order Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p>
              Are you sure you want to delete this <br /> food item from this
              order?
            </p>
            <div className="my-3 form-group">
              <textarea
                placeholder="Enter reason for deleting this food item..."
                onChange={handleNoteChange}
                rows={3}
                className="form-control"
                width="100%"
              ></textarea>
            </div>
            <div className="mb-3 btn btn-danger" onClick={handlesSubmit}>
              {loading ? "Submitting..." : "Submit"}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
