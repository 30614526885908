import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function AddConfiguration({ measurements, loadItems }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    item: "",
    measurement: "",
  });

  const handleItemName = (e) => {
    e.persist();
    setValues({
      ...values,
      item: e.target.value,
    });
  };

  const handleMeasurementChange = (e) => {
    e.persist();
    setValues({
      ...values,
      measurement: e.target.value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .post(`${process.env.REACT_APP_API_URL}/stock/create/item`, {
        measurement_id:
          values.measurement == "" ? measurements[0].id : values.measurement,
        name: values.item,
        place_id: localStorage.getItem("place"),
      })
      .then(() => {
        loadItems();
        handleClose();
        toast.success("Item added");
      })
      .catch((err) => {
        toast.error(err.response.data);
        console.log(err.response.data);
      })
      .finally(() => setLoading(false));
  };

  const addbuttonStyle = {
    background: "red",
    padding: "0.3rem 1.8rem",
    color: "white",
    marginLeft: "2rem",
    fontWeight: "700",
    cursor: 'pointer'
  };

  return (
    <>
      <button className="btn" onClick={handleShow} style={addbuttonStyle}>
        Add Stock item
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Stock Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group mb-3">
            <label>Stock Item name</label>
            <input className="form-control" onChange={handleItemName} />
          </div>
          <div className="form-group mb-4">
            <label>Measurement</label>
            <select className="form-control" onChange={handleMeasurementChange}>
              {measurements.map((m) => (
                <option key={m.id} value={m.id}>
                  {m.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group mb-3">
            <div className="btn btn-danger" onClick={handleSubmit}>
              {loading ? "Submitting..." : "Submit"}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
