import React, { useState } from "react";
import { Modal } from "react-bootstrap";

export default function AddSupplier() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addbuttonStyle = {
    background: "red",
    padding: "0.3rem 1.8rem",
    color: "white",
    marginLeft: "2rem",
    fontWeight: "700",
  };

  return (
    <>
      <button className="btn" style={addbuttonStyle} onClick={handleShow}>
        Add new supplier
      </button>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add new Supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group mb-3">
              <label>Supplier name</label>
              <input className="form-control" />
            </div>
            <div className="form-group mb-4">
              <label>Supplier phone number (12 characters)</label>
              <input className="form-control" type="number" />
            </div>
            <div className="form-group mb-3">
              <button className="btn btn-danger">Submit</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
