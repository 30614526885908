import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useRef, useState } from "react";
import { Button, Card, Col, Container, Form, Modal, Row, Spinner, Stack, Table } from "react-bootstrap";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
import NavigationBar from "../components/NavigationBar";

const place = localStorage.getItem("place");
const token = localStorage.getItem("token");

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const CreateShiftModal = () => {
  const queryClient = useQueryClient();
  const [show, setShow] = useState(false);
  const openingTimeRef = useRef(null);
  const [openingTime, setOpeningTime] = useState("");
  const closingTimeRef = useRef(null);
  const [closingTime, setClosingTime] = useState("");

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return axios.post("/shift/store/shift", {
        opening_time: openingTime + ":00",
        closing_time: closingTime + ":00",
        place_id: place,
      });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["shifts"] });

      toast.success("SHIFT CREATED SUCCESSFULLY");

      setShow(false);

      setOpeningTime("");
      setClosingTime("");
    },
    onError: (err) => {
      console.log(err);
      toast.error(err.response.data.message);
    },
  });

  return (
    <>
      <Button variant="danger" onClick={() => setShow(true)}>
        ADD NEW SHIFT
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>CREATE SHIFT</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            mutate();
          }}
        >
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>OPENING TIME</Form.Label>
                  <Form.Control
                    type="time"
                    required
                    className="p-2 w-100"
                    ref={openingTimeRef}
                    value={openingTime}
                    onChange={(e) => setOpeningTime(e.target.value)}
                    onFocus={(e) => openingTimeRef.current.showPicker()}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>CLOSING TIME</Form.Label>
                  <Form.Control
                    type="time"
                    required
                    className="p-2 w-100"
                    ref={closingTimeRef}
                    value={closingTime}
                    onChange={(e) => setClosingTime(e.target.value)}
                    onFocus={(e) => closingTimeRef.current.showPicker()}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" disabled={isLoading} type="su">
              {isLoading ? (
                <Stack direction="horizontal" gap={2}>
                  <Spinner as="span" animation="grow" size="sm" />
                  <span>SUBMITTING...</span>
                </Stack>
              ) : (
                <span>SUBMIT</span>
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const EditShiftModal = ({ id, opening_time, closing_time }) => {
  const queryClient = useQueryClient();
  const openingTimeRef = useRef(null);
  const [openingTime, setOpeningTime] = useState(opening_time.slice(0, -3));
  const closingTimeRef = useRef(null);
  const [closingTime, setClosingTime] = useState(closing_time.slice(0, -3));

  const [show, setShow] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      return axios.put(`/shift/update/shift/${id}`, {
        opening_time: openingTime + ":00",
        closing_time: closingTime + ":00",
        place_id: place,
      });
    },
    onSuccess: (res) => {
      toast.success("SHIFT UPDATED SUCCESSFULLY");
      setShow(false);

      setOpeningTime("");
      setClosingTime("");

      queryClient.invalidateQueries({ queryKey: ["shifts"] });
    },
    onError: (err) => {
      console.log(err);
      toast.error(err.response.data.message);
    },
  });

  return (
    <>
      <Button variant="success" onClick={() => setShow(true)} size="sm">
        EDIT
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>EDIT SHIFT</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            mutate();
          }}
        >
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>OPENING TIME</Form.Label>
                  <Form.Control
                    type="time"
                    required
                    className="p-2 w-100"
                    ref={openingTimeRef}
                    value={openingTime}
                    onChange={(e) => setOpeningTime(e.target.value)}
                    onFocus={(e) => openingTimeRef.current.showPicker()}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>CLOSING TIME</Form.Label>
                  <Form.Control
                    type="time"
                    required
                    className="p-2 w-100"
                    ref={closingTimeRef}
                    value={closingTime}
                    onChange={(e) => setClosingTime(e.target.value)}
                    onFocus={(e) => closingTimeRef.current.showPicker()}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" disabled={isLoading} type="su">
              {isLoading ? (
                <Stack direction="horizontal" gap={2}>
                  <Spinner as="span" animation="grow" size="sm" />
                  <span>SUBMITTING...</span>
                </Stack>
              ) : (
                <span>SUBMIT</span>
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const ShiftsTable = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["shifts"],
    queryFn: () => axios.post(`/shift/list/shift`, { place_id: place }).then((res) => res.data.data),
  });

  return (
    <Card>
      <Card.Body>
        <Row className="g-3">
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center ">
              <div className="bg-danger" style={{ height: "1rem", width: "1rem", marginRight: 10 }}></div>
              <div style={{ fontSize: 24 }}>SHIFTS</div>
            </div>{" "}
            <CreateShiftModal />
          </Col>
          <Col md={12}>
            {isLoading ? (
              <div className="d-flex justify-content-center p-5">
                <Bars color="red" />
              </div>
            ) : (
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="p-2">OPENING TIME</th>
                    <th className="p-2">CLOSING TIME</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((shift) => (
                    <tr key={shift.id}>
                      <td className="p-2">{shift.opening_time}</td>
                      <td className="p-2">{shift.closing_time}</td>
                      <td className="p-2 d-flex justify-content-end">
                        <EditShiftModal {...shift} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const DAYS = [
  {
    value: 1,
    label: "MONDAY",
  },
  {
    value: 2,
    label: "TUESDAY",
  },
  {
    value: 3,
    label: "WEDNESDAY",
  },
  {
    value: 4,
    label: "THURSDAY",
  },
  {
    value: 5,
    label: "FRIDAY",
  },
  {
    value: 6,
    label: "SATURDAY",
  },
  {
    value: 7,
    label: "SUNDAY",
  },
];

const AssignShiftModal = ({ employee }) => {
  const queryClient = useQueryClient();

  const [show, setShow] = useState(false);

  const [day, setDay] = useState("");
  const [shift, setShift] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: ["shifts"],
    queryFn: () => axios.post(`/shift/list/shift`, { place_id: place }).then((res) => res.data.data),
  });

  const { mutate, isLoading: isSubmitting } = useMutation({
    mutationFn: () => {
      return axios.post("/shift/store/employee/shift", {
        employee_id: employee.id,
        day_id: day,
        shift_id: shift,
      });
    },
    onSuccess: (res) => {
      toast.success(res.data.message.toLocaleUpperCase());

      console.log(res);

      setShow(false);

      setDay("");
      setShift("");

      queryClient.invalidateQueries({ queryKey: ["employee-shifts", employee.id] });
    },
    onError: (err) => {
      console.log(err);
      toast.error(err.response.data.message);
    },
  });

  return (
    <>
      <Button variant="success" size="sm" onClick={() => setShow(true)}>
        ASSIGN SHIFT
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>ASSIGN SHIFT</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            mutate();
          }}
        >
          <Modal.Body>
            <Stack gap={3}>
              <Form.Group>
                <Form.Label>
                  EMPLOYEE <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select size="lg" disabled>
                  <option value={employee.id}>{employee.user.name}</option>
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  DAY <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select size="lg" onChange={(e) => setDay(e.target.value)} value={day} required>
                  <option value="" disabled></option>
                  {DAYS.map((day) => (
                    <option key={day.value} value={day.value}>
                      {day.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  SHIFT <span className="text-danger">*</span>
                </Form.Label>
                <Form.Select size="lg" onChange={(e) => setShift(e.target.value)} value={shift} required>
                  <option value="" disabled></option>
                  {data.map((shift) => (
                    <option key={shift.id} value={shift.id}>
                      {shift.opening_time} - {shift.closing_time}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Stack>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" disabled={isSubmitting} type="su">
              {isSubmitting ? (
                <Stack direction="horizontal" gap={2}>
                  <Spinner as="span" animation="grow" size="sm" />
                  <span>SUBMITTING...</span>
                </Stack>
              ) : (
                <span>SUBMIT</span>
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const ViewShiftModal = ({ employee }) => {
  const [show, setShow] = useState(false);
  const [shiftId, setShiftId] = useState(null);

  const queryClient = useQueryClient();

  const { data = [], isLoading } = useQuery({
    queryKey: ["employee-shifts", employee.id],
    queryFn: () => {
      return axios
        .post(`/shift/list/employee/shift`, {
          employee_id: employee.id,
          // place_id: place,
        })
        .then((res) => res.data.data);
    },
    enabled: show,
  });

  const { mutate, isLoading: isDeleting } = useMutation({
    mutationFn: (id) => {
      setShiftId(id);
      return axios.delete(`/shift/delete/employee-shift/${id}`);
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["employee-shifts", employee.id] });

      toast.success("SHIFT DELETED SUCCESSFULLY");
    },
    onError: (err) => {
      console.log(err);
      toast.error(err.response.data.message);
    },
  });

  return (
    <>
      <Button variant="secondary" size="sm" onClick={() => setShow(true)}>
        VIEW SHIFTS
      </Button>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> {employee?.user?.name?.toLocaleUpperCase()} SHIFTS</Modal.Title>
        </Modal.Header>
        <Form>
          <Modal.Body>
            {isLoading ? (
              <div className="d-flex justify-content-center p-5">
                <Bars color="red" />
              </div>
            ) : (
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="p-2">DAY</th>
                    <th className="p-2">OPENING TIME</th>
                    <th className="p-2">CLOSING TIME</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((shift) => (
                    <tr key={shift.id}>
                      <td className="p-2">{shift?.day?.name}</td>
                      <td className="p-2">{shift?.shift?.opening_time}</td>
                      <td className="p-2">{shift?.shift?.closing_time}</td>
                      <td>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => mutate(shift.id)}
                          disabled={shift.id === shiftId && isDeleting}
                        >
                          DELETE
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const EmployeesTable = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["employees"],
    queryFn: () => axios.get(`/employee/${place}`).then((res) => res.data),
  });

  const HEADERS = [
    {
      prop: "name",
      title: "NAME",
      isSortable: true,
      cell: (row) => row.user.name,
    },
    {
      prop: "phone",
      title: "PHONE NUMBER",
      isSortable: true,
      cell: (row) => row.user.phone,
    },
    {
      prop: "actions",
      cell: (row) => (
        <Stack direction="horizontal" gap={3} className="justify-content-end">
          <AssignShiftModal employee={row} />
          <ViewShiftModal employee={row} />
        </Stack>
      ),
    },
  ];

  return (
    <Card>
      <Card.Body>
        <Row className="g-3">
          <Col md={12} className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center ">
              <div className="bg-danger" style={{ height: "1rem", width: "1rem", marginRight: 10 }}></div>
              <div style={{ fontSize: 24 }}>EMPLOYEES</div>
            </div>
          </Col>
          <Col md={12}>
            {isLoading ? (
              <div className="d-flex justify-content-center p-5">
                <Bars color="red" />
              </div>
            ) : (
              <DatatableWrapper
                body={data}
                headers={HEADERS}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 10,
                    options: [5, 10, 15, 20],
                  },
                }}
              >
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const Shifts = () => {
  const containerStyles = {
    background: "#f7f7f7",
    height: "100vh",
    padding: "2rem 0 0 0",
  };

  return (
    <>
      <NavigationBar />
      <Container fluid style={containerStyles}>
        <Container>
          <Stack gap={3}>
            <ShiftsTable />
            <EmployeesTable />
          </Stack>
        </Container>
      </Container>
    </>
  );
};

export default Shifts;
