import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function DeleteMultiple(orders, loadTransactions) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const deleteMultiple = () => {

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    console.log(orders);

    axios
      .post(`${process.env.REACT_APP_API_URL}/order/multiple/delete`, orders)
      .then(() => {
        handleClose();
        console.log('success');
        window.location.reload()
      })
      .catch((err) => {
        handleClose();
        toast.error(err);
      });
  };

  return (
    <>
      <div
        className="btn btn-danger text-white btn-sm ms-5 px-4 py-2"
        onClick={handleShow}
      >
        Refund
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation of removal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          Remove all selected orders?
          <br />
          <br />
          <div className="btn btn-danger" onClick={deleteMultiple}>
            Confrim
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
