import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";

export default function DeleteDrinkStock({ drink, loadDrinks }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => {
    console.log(drink);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/drink/stock/delete/${
        drink.id
      }/${localStorage.getItem("place")}`,
    })
      .then(() => {
        handleCloseDeleteModal();
        loadDrinks();
        toast.success("Drink stock deleted");
      })
      .catch((err) => {
        handleCloseDeleteModal();
        console.log(err);
        toast.error(err.response.data);
      });
  };

  return (
    <>
      <FaTrashAlt
        color="red"
        onClick={handleShowDeleteModal}
        className="ms-4"
      />

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete add on</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this drink?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
