import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaUpload } from "react-icons/fa";
import ReactSelect from "react-select";
import { toast } from "react-toastify";

export default function ShiftToCurrentStock({ id, loadStocks }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([]);
  const [selectedFoodType, setSelectedFoodType] = useState("");

  const handleQuantityChange = (e) => {
    e.persist();
    setQuantity(e.target.value);
  };

  const handleTypeChange = (e) => {
    setSelectedFoodType(e.value);
  };

  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/type/place/${localStorage.getItem(
          "place"
        )}`
      )
      .then((res) => {
        setTypes(res.data.types.map((x) => ({ value: x.id, label: x.name })));
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  }, []);

  const handleSubmit = () => {
    
    setLoading(true);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    axios
      .post(`${process.env.REACT_APP_API_URL}/stock/shiftStockCount`, {
        quantity: quantity,
        id: id,
        type_id: selectedFoodType
      })
      .then(() => {
        handleClose();
        loadStocks();
        setQuantity(0);
        toast.success("Stock count updated");
      })
      .catch((err) => toast.error(err.response.data))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <FaUpload size={14} onClick={handleShow} />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Shift current stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p>Add new current stock quantity</p>

            <ReactSelect
              options={types}
              onChange={handleTypeChange}
              placeholder="Select food category"
            />

            <input
              className="form-control mb-3 mt-4"
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
            />

            {loading ? (
              <div className="btn btn-danger">Submitting...</div>
            ) : (
              <div onClick={handleSubmit} className="btn btn-danger">
                Submit
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
