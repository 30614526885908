import "./App.css";
import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import SignIn from "./pages/auth/SignIn";
import Register from "./pages/auth/Register";
import Home from "./pages/Home";
import Reviews from "./pages/Reviews";
import FoodItems from "./pages/FoodItems";
import Customers from "./pages/Customers";
import Transactions from "./pages/Transactions";
import Reservation from "./pages/Reservation";
import OrderStatus from "./pages/OrderStatus";
import Terms from "./pages/Terms";
import AddFoodItem from "./pages/AddFoodItem";
import Dashboard from "./pages/dashboard/Dashboard";
import RestaurantOnboarding from "./pages/auth/RestaurantOnboarding";
import Places from "./pages/Places";
import Support from "./pages/Support";
import Settings from "./pages/Settings";
import Tables from "./pages/Tables";
import MenuTypes from "./pages/MenuTypes";
import Authentication from "./pages/Authentication";
import Drinks from "./pages/Drinks";
import RegisterRestaurant from "./pages/auth/RegisterRestaurant";
import ForgotPassword from "./pages/auth/ForgotPassword";
import PasswordReset from "./pages/auth/PasswordReset";
import Schedule from "./pages/Schedule";
import Splash from "./pages/Splash";
import InventoryIndex from "./pages/inventory/inventoryIndex";
import SupplierIndex from "./pages/suppliers/supplierIndex";
import MenuAddOns from "./pages/MenuAddOns";
import DeletedFoodItems from "./pages/DeletedFoodItems";
import DeletedDrinkItems from "./pages/DeletedDrinkItems";
import AddNewMenuStock from "./pages/AddNewMenuStock";
import InventoryHome from "./pages/inventory/InventoryHome";
import Configuraiton from "./pages/inventory/Configuration";
import PastRequests from "./pages/inventory/PastRequests";
import StockHistory from "./pages/inventory/StockHistory";
import Restaurant from "./pages/inventory/Restaurant";
import Sessions from "./pages/Sessions";
import CashInCashOut from "./pages/CashInCashOut";
import Reports from "./pages/Reports";

import "react-toastify/dist/ReactToastify.css";
import Shifts from "./pages/Shifts";
import ErrorBoundary from './ErrorBoundary';
import NotFound from "./components/NotFound";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <div className="App">
        {localStorage.getItem("role") === '4' ? (
          <Routes>
            <Route path="/" element={<Splash />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/register" element={<Register />} />
            <Route path="/home" element={<Home />} />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/fooditems" element={<FoodItems />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/reservation" element={<Reservation />} />
            <Route path="/orderstatus" element={<OrderStatus />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/addItem" element={<AddFoodItem />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/onboarding" element={<RestaurantOnboarding />} />
            <Route path="/restaurant/register" element={<RegisterRestaurant />} />
            <Route path="/places" element={<Places />} />
            <Route path="/support" element={<Support />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/tables" element={<Tables />} />
            <Route path="/types" element={<MenuTypes />} />
            <Route path="/authentication" element={<Authentication />} />
            <Route path="/drinks" element={<Drinks />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/password/reset/:tokenId" element={<PasswordReset />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/inventory" element={<InventoryIndex />} />
            <Route path="/supplier" element={<SupplierIndex />} />
            <Route path="/addon" element={<MenuAddOns />} />
            <Route path="/deletedfood" element={<DeletedFoodItems />} />
            <Route path="/deleteddrinks" element={<DeletedDrinkItems />} />
            <Route path="/addmenustock" element={<AddNewMenuStock />} />
            <Route path="/inventoryhome" element={<InventoryHome />} />
            <Route path="/configuration" element={<Configuraiton />} />
            <Route path="/pastrequests" element={<PastRequests />} />
            <Route path="/stockhistory" element={<StockHistory />} />
            <Route path="/invetoryrestaurant" element={<Restaurant />} />
            <Route path="/sessions" element={<Sessions />} />
            <Route path="/cash-in-cash-out" element={<CashInCashOut />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/shifts" element={<Shifts />} />
          </Routes>
        ) : (
          <ErrorBoundary>
            <Routes>
              <Route path="/" element={<Splash />} />
              <Route path="/login" element={<SignIn />} />
              <Route path="/register" element={<Register />} />
              <Route path="/restaurant/register" element={<RegisterRestaurant />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/orderstatus" element={<OrderStatus />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ErrorBoundary>
        )}
        <ToastContainer />
      </div>
    </QueryClientProvider>
  );
};

export default App;