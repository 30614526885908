import React from "react";
import { FaPen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function AddMenuStockCount({ menuID }) {
  const navigate = useNavigate();

  const nextPage = () => {
    localStorage.setItem("menuID", menuID);
    navigate("/addmenustock");
  };

  return (
    <FaPen
      className="me-4"
      style={{ textDecoration: "none", cursor: "pointer" }}
      onClick={nextPage}
    />
  );
}
