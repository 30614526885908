import React, { useCallback, useEffect, useState } from "react";
import NavigationBar from "../components/NavigationBar";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Badge, Col, Modal, Row, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import EditEmployeeDrawer from "../components/EditEmployeeDrawer";
import { FaUser } from "react-icons/fa";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";

export default function Authentication() {
  const checkRole = (role) => {
    let value = "";

    switch (role) {
      case 1:
        value = "Waiter";
        break;
      case 2:
        value = "Cashier";
        break;
      case 3:
        value = "Manager";
        break;
      case 4:
        value = "Owner";
        break;
      default:
        value = "Waiter";
        break;
    }
    return value;
  };

  const STORY_HEADERS = [
    {
      prop: "name",
      title: "Name",
      isSortable: true,
      cell: (row) => row.user.name,
    },
    {
      prop: "status",
      title: "Status",
      isSortable: true,
      cell: (row) =>
        row.status ? (
          <Badge pill bg="success">
            Active
          </Badge>
        ) : (
          <Badge pill bg="danger">
            Inactive
          </Badge>
        ),
    },
    {
      prop: "phone",
      title: "Phone number",
      isSortable: true,
      cell: (row) => row.user.phone,
    },
    {
      prop: "email",
      title: "Email Address",
      isSortable: true,
      cell: (row) => row.user.email,
    },
    {
      prop: "code",
      title: "Code",
      cell: (row) => row.code || "",
    },
    {
      prop: "role",
      title: "Role",
      isSortable: true,
      cell: (row) => checkRole(row.role),
    },
    {
      prop: "id",
      title: "Actions",
      isSortable: true,
      cell: (row) => (
        <>
          <EditEmployeeDrawer user={row.user} code={row.code} loadUsers={loadUsers} />
          <FaUser className="ms-2" onClick={() => changeStatus(row.user)} />
        </>
      ),
    },
  ];

  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingWaiter, setLoadingWaiter] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const loadUsers = useCallback(async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    try {
      let t = await axios.get(
        `${process.env.REACT_APP_API_URL}/employee/${localStorage.getItem(
          "place"
        )}`
      );
      setEmployees(t.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  });

  const inputStyle = {
    height: "4rem",
    background: "#f7f7f7",
  };

  useEffect(() => {
    document.body.style.backgroundColor = "#f7f7f7";
    loadUsers();
    setValues({
      ...values,
      role: roles[0].value,
    });
  }, []);

  const addbuttonStyle = {
    background: "red",
    padding: "0.3rem 1.8rem",
    color: "white",
    marginLeft: "2rem",
    fontWeight: "700",
  };

  const changeStatus = (user) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/waiter/statusUpdate`, {
        user_id: user.id,
        place_id: localStorage.getItem("place"),
      })
      .then(() => {
        loadUsers();
        toast.success("Status updated");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Status could not be updated");
      });
  };

  const submitButtonStyle = {
    fontWeight: "800",
    height: "3rem",
    background: "#214071",
    border: "1px solid #214071",
  };

  const [values, setValues] = useState({
    name: "",
    phone: "",
    email: "",
    code: "",
    pass: "",
    role: 0,
  });

  const handleNameChange = (e) => {
    e.persist();
    setValues({
      ...values,
      name: e.target.value,
    });
  };

  const handleEmailChange = (e) => {
    e.persist();
    setValues({
      ...values,
      email: e.target.value,
    });
  };

  const handlePhoneChange = (e) => {
    e.persist();
    setValues({
      ...values,
      phone: e.target.value,
    });
  };

  const handlePassChange = (e) => {
    e.persist();
    setValues({
      ...values,
      pass: e.target.value,
    });
  };

  const handleRoleChange = (e) => {
    e.persist();
    setValues({
      ...values,
      role: e.target.value,
    });
  };

  const handleCodeChange = (e) => {
    e.persist();
    setValues({
      ...values,
      code: e.target.value,
    });
  };

  const roles = [
    {
      value: 1,
      text: "Waiter",
    },
    {
      value: 2,
      text: "Cashier",
    },
    {
      value: 3,
      text: "Manager",
    },{
      value: 6,
      text: "Store keeper"
    },
    {
      value: 4,
      text: "Admin"
    }
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingWaiter(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/waiter/register`, {
        name: values.name,
        phone: values.phone,
        email: values.email,
        password: values.pass,
        code: values.code,
        role: values.role,
        place_id: localStorage.getItem("place"),
      })
      .then(() => {
        setLoadingWaiter(false);
        handleClose();
        loadUsers();
        toast.success("Employee added");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data);
      })
      .finally(() => setLoadingWaiter(false));
  };

  return (
    <>
      <NavigationBar />
      <div
        className="container-fluid"
        style={{
          padding: "2rem 0 0 0",
        }}
      >
        <div className="d-flex flex-row justify-content-start">
          <div
            className="bg-danger me-3 mt-2"
            style={{ width: "1rem", height: "1rem" }}
          >
            {" "}
          </div>
          <h4 style={{ fontWeight: "700" }}>Employees</h4>
          <button className="btn" style={addbuttonStyle} onClick={handleShow}>
            Add Employee
          </button>
        </div>
        <div className="container">
          <div className="mt-3">
            {loading ? (
              <Bars
                heigth="100"
                width="1400"
                color="red"
                ariaLabel="loading-indicator"
              />
            ) : (
              <DatatableWrapper
                body={employees}
                headers={STORY_HEADERS}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 10,
                    options: [5, 10, 15, 20],
                  },
                }}
              >
                <Row className="mb-4 p-2">
                  <Col
                    xs={12}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                  >
                    <Filter classes={{ clearButton: "btn-danger" }} />
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                  >
                    <PaginationOptions alwaysShowPagination="true" />
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                  >
                    <Pagination classes={{ button: "btn-danger" }} />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>
            )}
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>New Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label>Name</label>
              <br />
              <input
                style={inputStyle}
                onChange={handleNameChange}
                className="form-control"
                placeholder="John Doe"
              />
            </div>
            <div className="form-group mb-3">
              <label>Email</label>
              <br />
              <input
                style={inputStyle}
                onChange={handleEmailChange}
                className="form-control"
                placeholder="eg. johndoe@tayari.com"
              />
            </div>
           {
            localStorage.getItem('role') !== 4 &&  <div className="form-group mb-3">
            <label>Password</label>
            <br />
            <input
              style={inputStyle}
              onChange={handlePassChange}
              className="form-control"
            />
          </div>
           }
            <div className="form-group mb-3">
              <label>Role</label>
              <br />
              <select
                style={inputStyle}
                className="form-control"
                onChange={handleRoleChange}
              >
                {roles.map((r) => (
                  <option key={r.value} value={r.value}>
                    {r.text}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group mb-3">
              <label>Phone</label>
              <br />
              <input
                style={inputStyle}
                onChange={handlePhoneChange}
                className="form-control"
                placeholder="2557XXX..."
              />
            </div>
            <div className="form-group mb-4">
              <label>Employee Code</label>
              <br />
              <input
                style={inputStyle}
                onChange={handleCodeChange}
                className="form-control"
                placeholder="Enter 4 digit code"
              />
            </div>
            <div className="form-group mb-3">
              <button
                className="btn btn-danger text-white"
                style={submitButtonStyle}
                type="submit"
              >
                {loadingWaiter ? "Submitting..." : " Submit"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
