import axios from 'axios';
import React, { useCallback, useEffect } from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from 'react-bs-datatable';
import NavigationBar from '../../components/NavigationBar'
import AddSupplier from './AddSupplier';

export default function SupplierIndex() {
  
    const data = [
        {
          companyName: "AWS",
          companyPhone: "255782835136",
          amount: 15
        },
        {
            companyName: "Microsoft",
            companyPhone: "255782835136",
            amount: 15
        }
      ]
    
      const STORY_HEADERS = [
        
        {
          prop: "companyName",
          title: "Name",
        },
    
        {
          prop: "companyPhone",
          title: "Phone",
        },
        {
          prop: "amount", 
          title: "Action",
          cell: row => <button className='btn btn-sm btn-danger'>Request goods</button>
        }
      ];
    
    
      const loadSuppliers = useCallback(async () => {
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("token")}`;
      
          try {
            let f = await axios.get(
              `${process.env.REACT_APP_API_URL}/menu/place/${localStorage.getItem(
                "place"
              )}`
            );
      
            let t = await axios.get(
              `${process.env.REACT_APP_API_URL}/type/place/${localStorage.getItem(
                "place"
              )}`
            );
      
            // setFood(f.data);
            // setTypes(t.data.types);
            // setLoading(false);
          } catch (error) {
            console.error(error);
          }
      })
    
      useEffect(() => {
    
      }, []);
    
    
      return (
        <>
        <NavigationBar />
        <div
          className="container-fluid"
          style={{
            padding: "2rem 0 0 0",
          }}
        >
          <div className="d-flex flex-row justify-content-start">
            <div
              className="bg-danger me-3 mt-2"
              style={{ width: "1rem", height: "1rem" }}
            >
              {" "}
            </div>
            <h4 style={{ fontWeight: "700" }}>Suppliers &nbsp; </h4>
            <AddSupplier />
          </div>
          <div className='container'>
          <DatatableWrapper
                    body={data}
                    headers={STORY_HEADERS}
                    paginationOptionsProps={{
                      initialState: {
                        rowsPerPage: 10,
                        options: [5, 10, 15, 20],
                      },
                    }}
                  >
                    <Row className="mb-4 p-2">
                      <Col
                        xs={12}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Filter classes={{ clearButton: "btn-danger" }} />
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                      >
                        <PaginationOptions alwaysShowPagination="true" />
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Pagination classes={{ button: "btn-danger" }} />
                      </Col>
                    </Row>
                    <Table>
                      <TableHeader />
                      <TableBody />
                    </Table>
                  </DatatableWrapper>
          </div>
        </div>
      </>
      )
}
