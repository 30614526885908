import React, { useCallback, useEffect, useState } from "react";
import NavigationBar from "../components/NavigationBar";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Badge, Col, Row, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import TransactionDrawer from "../components/TransactionDrawer";
import DeleteOrder from "../components/DeleteOrder";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import "../index.css";
import DeleteMultiple from "../components/DeleteMultipleOrders";
import DeleteRequests from "../components/DeleteRequests";

export default function Transactions() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const selectItems = (id) => {
    let itemIndex = orders.findIndex((x) => x.id == id);

    let content = [...orders];
    content[itemIndex].selected = !content[itemIndex].selected;

    setOrders([...content]);
  };

  const STORY_HEADERS = [
    {
      prop: "id",
      title: "Select",
      cell: (row, i) => (
        <div className="form-check" id={i}>
          <input
            style={{ accentColor: "red" }}
            type="checkbox"
            value={row.selected}
            id={i}
            onChange={() => selectItems(row.id)}
          />
        </div>
      ),
    },
    {
      prop: "created_at",
      title: "Created",
      cell: (row) => moment(row.created_at).format("DD/MM/YYYY H:mm"),
    },
    {
      prop: "name",
      title: "Name",
      isSortable: true,
      cell: (row) => (row.customer?.name == null ? "POS" : row.customer?.name),
    },
    {
      prop: "cost",
      title: "Amount",
      isSortable: true,
    },
    {
      prop: "order_number",
      title: "Order No",
      isSortable: true,
    },
    {
      prop: "payment_method",
      title: "Payment",
      isSortable: true,
      cell: (row) =>
        row.payment_method == 1 ? (
          <Badge pill bg="primary">
            Cash
          </Badge>
        ) : (
          <Badge pill bg="dark">
            Mobile
          </Badge>
        ),
    },
    {
      prop: "type",
      title: "Type",
      isSortable: true,
      cell: (row) =>
        row.type == 1
          ? "Pre-order"
          : row.type == 2
          ? "Dine-in"
          : row.type == 3
          ? "Reservation"
          : "Delivery",
    },
    {
      prop: "payment_status",
      title: "Status",
      isSortable: true,
      cell: (row) =>
        row.payment_status ? (
          <Badge pill bg="success">
            Paid
          </Badge>
        ) : (
          <Badge pill bg="danger">
            Not Paid
          </Badge>
        ),
    },
    {
      prop: "is_pos",
      title: "Actions",
      cell: (row) =>
        row.is_pos ? (
          <Badge pill bg="primary">
            POS
          </Badge>
        ) : (
          <Badge pill bg="dark">
            Normal
          </Badge>
        ),
    },
    {
      prop: "updated_at",
      title: "Actions",
      cell: (row) => (
        <>
          <TransactionDrawer content={row} loadTransactions={loadTransactions} />
          <DeleteOrder order={row} loadTransactions={loadTransactions} />
        </>
      ),
    },
  ];

  const loadTransactions = useCallback(async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    try {
      let t = await axios.get(
        `${process.env.REACT_APP_API_URL}/order/place/${localStorage.getItem(
          "place"
        )}`
      );
      setOrders(t.data.map((x) => ({ ...x, selected: false })));
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions]);

  return (
    <>
      <NavigationBar />
      <div
        className="container-fluid"
        style={{
          background: "#f7f7f7",
          height: "100vh",
          padding: "2rem 0 0 0",
        }}
      >
        <div className="d-flex flex-row bg-[#f7f7f7] gap-4 w-screen justify-between items-center">
          <div className="d-flex flex-row justify-content-start">
            <div
              className="bg-danger me-3 mt-2"
              style={{ width: "1rem", height: "1rem" }}
            >
              {" "}
            </div>
            <h4 style={{ fontWeight: "700" }}>Transactions</h4>
            <DeleteMultiple
              loadTransactions={loadTransactions}
              orders={orders
                .filter((x) => x.selected == true)
                .map((v) => ({ id: v.id }))}
            />
          </div>
          {localStorage.getItem("role") === "4" &&
            <DeleteRequests loadTransactions={loadTransactions} />
          }
        </div>
        <div className="container">
          <div className="mt-3">
            {loading ? (
              <Bars
                heigth="100"
                width="1400"
                color="red"
                ariaLabel="loading-indicator"
              />
            ) : (
              <DatatableWrapper
                selectableRows
                body={orders}
                headers={STORY_HEADERS}
                paginationOptionsProps={{
                  initialState: {
                    rowsPerPage: 10,
                    options: [5, 10, 15, 20],
                  },
                }}
              >
                <Row className="mb-4 p-2">
                  <Col
                    xs={12}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                  >
                    <Filter classes={{ clearButton: "btn-danger" }} />
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                  >
                    <PaginationOptions />
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                  >
                    <Pagination classes={{ button: "btn-danger" }} />
                  </Col>
                </Row>
                <Table>
                  <TableHeader />
                  <TableBody />
                </Table>
              </DatatableWrapper>
            )}
          </div>
        </div>
      </div>
    </>
  );
}