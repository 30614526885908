import axios from "axios";
import React, { useEffect, useState } from "react";
import NavigationBar from "../components/NavigationBar";
import moment from "moment";
import { DatatableWrapper, Filter, Pagination, PaginationOptions, TableBody, TableHeader } from "react-bs-datatable";
import { Badge, Card, Col, Container, Row, Table } from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import { useQuery } from "@tanstack/react-query";

import "bootstrap/dist/css/bootstrap.css";

const place = localStorage.getItem("place");
const token = localStorage.getItem("token");

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const HEADERS = [
  {
    prop: "user",
    title: "User Name",
    isSortable: true,
    cell: (row) => row.user.name,
  },

  {
    prop: "opening_amount",
    title: "Opening Amount",
    isSortable: true,
    cell: (row) => row.opening_amount.toLocaleString(),
  },
  {
    prop: "closing_amount",
    title: "Closing Amount",
    isSortable: true,
    cell: (row) => row.closing_amount.toLocaleString(),
  },
  {
    prop: "status",
    title: "Status",
    isSortable: true,
    cell: (row) =>
      row.status === 1 ? (
        <Badge pill bg="danger">
          Closed
        </Badge>
      ) : (
        <Badge pill bg="success">
          Open
        </Badge>
      ),
  },
  {
    prop: "created_at",
    title: "Created",
    isSortable: true,
    cell: (row) => moment(row.created_at).format("LLL"),
  },
];

const Sessions = () => {
  const { isLoading, data } = useQuery({
    queryKey: ["sessions"],
    queryFn: () => axios.post(`/pos/getsessions`, { place_id: place }).then((res) => res.data),
  });

  const sortedData = data ? [...data].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) : [];

  return (
    <>
      <NavigationBar />
      <Container
        fluid
        style={{
          background: "#f7f7f7",
          height: "100vh",
          padding: "2rem 0 0 0",
        }}
      >
        <div className="d-flex flex-row justify-content-start">
          <div className="bg-danger me-3 mt-2" style={{ width: "1rem", height: "1rem" }}>
            {" "}
          </div>
          <h4 style={{ fontWeight: "700" }}>Sessions</h4>
        </div>
        <Container>
          <Card>
            <Card.Body>
              {isLoading ? (
                <Bars heigth="100" width="1400" color="red" ariaLabel="loading-indicator" />
              ) : (
                <DatatableWrapper
                  body={sortedData}
                  headers={HEADERS}
                  paginationOptionsProps={{
                    initialState: {
                      rowsPerPage: 10,
                      options: [5, 10, 15, 20],
                    },
                  }}
                >
                  <Row className="mb-4 p-2">
                    <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                      <Filter classes={{ clearButton: "btn-danger" }} />
                    </Col>
                    <Col
                      xs={12}
                      sm={6}
                      lg={4}
                      className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                    >
                      <PaginationOptions alwaysShowPagination="true" />
                    </Col>
                    <Col xs={12} sm={6} lg={4} className="d-flex flex-col justify-content-end align-items-end">
                      <Pagination classes={{ button: "btn-danger" }} />
                    </Col>
                  </Row>
                  <Table>
                    <TableHeader />
                    <TableBody />
                  </Table>
                </DatatableWrapper>
              )}
            </Card.Body>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default Sessions;