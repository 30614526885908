import React from "react";
import { useNavigate } from "react-router-dom";

export default function ReturnBackButton() {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  const addbuttonStyle = {
    background: "red",
    padding: "0.3rem 1.8rem",
    color: "white",
    marginLeft: "2rem",
    fontWeight: "700",
    cursor: "pointer",
  };

  return (
    <button
      className="btn btn-danger ms-4"
      onClick={goBack}
      style={addbuttonStyle}
    >
      Return back
    </button>
  );
}
