import React, { useCallback, useEffect, useState } from "react";
import {
  DatatableWrapper,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Badge, Col, Row, Table } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { Bars } from "react-loader-spinner";
import ViewRequestItems from "../../components/ViewRequestItems";
import NavigationBar from "../../components/NavigationBar";
import ReturnBackButton from "../../components/ReturnBackButton";

export default function StockHistory() {
    const STORY_HEADERS = [
        {
          prop: "created_at",
          title: "Request date",
          cell: (row) => moment(row.created_at).format("DD-MM-YYYY"),
        },
        {
          prop: "id",
          title: "Requester",
          cell: (row) => row.user.name,
        },
        {
          prop: "items",
          title: "Quantity",
          cell: (row) => row.items.length,
        },
        {
          prop: "recieved",
          title: "Status",
          cell: (row) =>
            row.recieved > 0 ? (
              <Badge bg="success">Delivered</Badge>
            ) : (
              <Badge bg="danger">Not Delivered</Badge>
            ),
        },
        {
            prop: "type",
            title: "Action",
            cell: row => <div className="d-flex flex-row justify-content-end">
                <ViewRequestItems items={row.items} title="Confirmed items" />
            </div>
        }
      ];
    
      const [requests, setRequests] = useState([]);
      const [loading, setLoading] = useState(true);
    
      const loadData = useCallback(async () => {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("token")}`;
    
        let t = await axios.get(
          `${process.env.REACT_APP_API_URL}/stock/request/${localStorage.getItem(
            "place"
          )}`
        );
        console.log(t.data);
        setRequests(t.data.filter(x => x.recieved == 1));
      });
    
      useEffect(() => {
        document.body.style.backgroundColor = "#f7f7f7";
        setLoading(true);
        loadData().then(() => setLoading(false));
      }, []);
    
      return (
        <>
          <NavigationBar />
          <div
            className="container-fluid"
            style={{
              padding: "2rem 0 0 0",
            }}
          >
            <div className="d-flex flex-row justify-content-start">
              <div
                className="bg-danger me-3 mt-2"
                style={{ width: "1rem", height: "1rem" }}
              >
                {" "}
              </div>
              <h4 style={{ fontWeight: "700" }}>History</h4>
              <ReturnBackButton />
            </div>
            <div className="container">
              <div className="mt-3">
                {loading ? (
                  <Bars
                    heigth="100"
                    width="1400"
                    color="red"
                    ariaLabel="loading-indicator"
                  />
                ) : (
                  <DatatableWrapper
                    body={requests}
                    headers={STORY_HEADERS}
                    paginationOptionsProps={{
                      initialState: {
                        rowsPerPage: 10,
                        options: [5, 10, 15, 20],
                      },
                    }}
                  >
                    <Row className="mb-4 p-2">
                      <Col
                        xs={12}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      ></Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                      >
                        <PaginationOptions alwaysShowPagination="true" />
                      </Col>
                      <Col
                        xs={12}
                        sm={6}
                        lg={4}
                        className="d-flex flex-col justify-content-end align-items-end"
                      >
                        <Pagination classes={{ button: "btn-danger" }} />
                      </Col>
                    </Row>
                    <Table>
                      <TableHeader />
                      <TableBody />
                    </Table>
                  </DatatableWrapper>
                )}
              </div>
            </div>
          </div>
        </>
      );
}
