import React, { useEffect, useState } from 'react';
import { Offcanvas, Button, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdNotifications } from "react-icons/md";

const DeleteRequests = ({ handleShow }) => (
  <div className="cursor-pointer" onClick={handleShow}>
    <MdNotifications color="red" size={30} className="text-red" title="Deletion Requests" />
  </div>
);

const RequestItem = ({ request, handleApprove, handleDecline }) => (
  <ListGroup.Item>
    <p>
      Customer ID {request.customer_id} requested to delete order #{request.order_number} on {moment(request.created_at).format('DD/MM/YYYY H:mm')}
    </p>
    <div className='mb-3 d-flex justify-content-between'>
      <Button variant="success" onClick={() => handleApprove(request.id)}>Approve</Button>
      <Button variant="danger" onClick={() => handleDecline(request.id)}>Decline</Button>
    </div>
  </ListGroup.Item>
);

const RequestNotifications = ({ loadTransactions }) => {
  const [show, setShow] = useState(false);
  const [requests, setRequests] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchRequests = async () => {
      axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
      const place = localStorage.getItem("place");

      try {
        const response = await axios.get(`https://api.tayari.co.tz/api/v1/order/approval-orders/${place}`);
        setRequests(response.data);
      } catch (error) {
        toast.error("Failed to fetch deletion requests");
        console.error(error);
      }
    };

    fetchRequests();
  }, []);

  const handleApprove = async (requestId) => {
    const place = localStorage.getItem("place");
    try {
      await axios.post(`https://api.tayari.co.tz/api/v1/order/approve-delete/${requestId}`);
      toast.success("Order deletion approved");
      setRequests(requests.filter(request => request.id !== requestId));
      loadTransactions();
    } catch (error) {
      toast.error("Failed to approve deletion");
      console.error(error);
    }
  };

  const handleDecline = async (requestId) => {
    const place = localStorage.getItem("place");
    try {
      await axios.post(`https://api.tayari.co.tz/api/v1/order/decline-delete/${requestId}`);
      toast.success("Order deletion declined");
      setRequests(requests.filter(request => request.id !== requestId));
      loadTransactions();
    } catch (error) {
      toast.error("Failed to decline deletion");
      console.error(error);
    }
  };

  return (
    <>
      <DeleteRequests handleShow={handleShow} />
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Delete Requests</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ListGroup>
            {/* Check if requests is an array before attempting to map over it */}
            {Array.isArray(requests) && requests.length > 0 ? (
              requests.map(request => (
                <RequestItem 
                  key={request.id} 
                  request={request} 
                  handleApprove={handleApprove} 
                  handleDecline={handleDecline} 
                />
              ))
            ) : (
              <ListGroup.Item>No delete requests available</ListGroup.Item>
            )}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>
      <ToastContainer />
    </>
  );
};

export default RequestNotifications;