import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsImage, BsPercent } from "react-icons/bs";
import { toast } from "react-toastify";

export default function DiscountFood({ menu, loadFood }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setDiscount(menu.food_discount * 100);
    setShow(true);
  };

  const [discount, setDiscount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleDiscountChange = (e) => {
    e.persist();
    setDiscount(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("token")}`;

    const formData = new FormData();
    formData.append("menu_id", menu.id);
    formData.append("discount", discount);
    selectedImage !== null &&
      formData.append("banner", selectedImage, selectedImage.name);

    axios
      .post(`${process.env.REACT_APP_API_URL}/discount/food`, formData)
      .then((res) => {
        setLoading(false);
        handleClose();
        setSelectedImage(null);
        loadFood();
        toast.success(res.data);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data);
      });
  };

  return (
    <>
      <BsPercent onClick={handleShow} className="me-3" />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Food discount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label>Discount %</label>
              <input
                onChange={handleDiscountChange}
                value={discount}
                className="form-control"
                type="number"
                min="0"
                max="100"
              />
              <small>Enter 0 if no discount</small>
            </div>

            <div className="form-group mb-4">
              {selectedImage ? (
                <img
                  alt="img"
                  width={"250px"}
                  className="mb-3"
                  src={URL.createObjectURL(selectedImage)}
                />
              ) : menu?.offer_banner !== null ? (
                <img
                  alt={menu?.offer_banner}
                  width={"50%"}
                  src={`${process.env.REACT_APP_SITE_URL}/images/offers/${menu?.offer_banner}`}
                />
              ) : (
                <div
                  className="my-3 card bg-danger text-white"
                  style={{ width: "100%" }}
                >
                  <div className="card-body text-center">
                    <BsImage size="50" />

                    <p className="mt-3" style={{ fontWeight: "600" }}>
                      Offer banner (optional)
                    </p>
                  </div>
                </div>
              )}
              <input
                type="file"
                onChange={(event) => {
                  console.log(event.target.files[0]);
                  setSelectedImage(event.target.files[0]);
                }}
                className="form-control"
              />
            </div>

            <div className="form-group mb-3">
              <Button variant="warning" type="submit">
                {loading ? "Updating..." : "Update discount"}
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
