import React, { useState } from "react";
import axios from "axios";
import { Card, Col, Container, Row, Table, Button } from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import NavigationBar from "../components/NavigationBar";
import { useQuery } from "@tanstack/react-query";
import SalesBarChart from "../components/charts/SalesBarChart";
import SalesLineChart from "../components/charts/SalesLineChart";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import PurchaseBarChart from "../components/charts/PurchasesBarChart";

const place = localStorage.getItem("place");
const token = localStorage.getItem("token");

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

const containerStyles = {
  background: "#f7f7f7",
  height: "100vh",
  padding: "2rem 0 0 0",
};

const CashInCashOutTable = () => {
  const { isLoading, data } = useQuery({
    queryKey: ["cash-in-cash-out"],
    queryFn: () =>
      axios
        .post("/cash/report/cash-in-out", {
          place_id: place,
        })
        .then((res) => {
          const { user, employeelist, ...rest } = res.data.data;
          return rest;
        }),
  });

  if (isLoading) return <Bars height={100} width={1400} color="red" />;

  // Check if data is undefined or null and show an empty table if so
  const tableContent = data ? (
    Object.keys(data).map((key) => (
      <tr key={key}>
        <td>{key.split("_").join(" ").toLocaleUpperCase()}</td>
        <td>{data[key]?.toLocaleString() || "N/A"}</td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="2">No data available</td>
    </tr>
  );

  return (
    <Card>
      <Card.Body>
        <Card.Title className="d-flex align-items-center ">
          <div className="bg-danger" style={{ height: "1rem", width: "1rem", marginRight: 10 }}></div>
          <div style={{ fontSize: 24 }}>CASH IN - CASH OUT REPORT</div>
        </Card.Title>
        <div>
          <Table striped bordered hover>
            <tbody>{tableContent}</tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

const EmployeesSalesTable = () => {
  const [employeeId, setEmployeeId] = useState(null);

  const styles = {
    backgroundColor: "rgba(0,0,0,.075)",
  };

  const { isLoading, data } = useQuery({
    queryKey: ["employee-sales"],
    queryFn: () =>
      axios
        .post("/sales/report/all", {
          place_id: place,
        })
        .then((res) => res.data.data.customer),
  });

  if (isLoading) return <Bars height={100} width={1400} color="red" />;

  // Check if data is undefined or null and show an empty table if so
  const employees = data
    ? data.filter((employee) => employee.employee["place_id"] === Number(place))
    : [];

  return (
    <Card>
      <Card.Body>
        <Card.Title className="d-flex align-items-center ">
          <div className="bg-danger" style={{ height: "1rem", width: "1rem", marginRight: 10 }}></div>
          <div style={{ fontSize: 24 }}>EMPLOYEES SALES REPORT</div>
        </Card.Title>
        <div>
          <Table bordered hover>
            <thead>
              <tr>
                <th></th>
                <th>EMPLOYEE NAME</th>
                <th>TOTAL AMOUNT</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {employees.length > 0 ? (
                employees.map(({ id, name, sales_monthly, sales_total }) => (
                  <React.Fragment key={id}>
                    <tr style={employeeId === id ? styles : undefined}>
                      <td style={{ width: 50 }}>
                        <Button
                          size="sm"
                          onClick={() => setEmployeeId(employeeId === id ? null : id)}
                          variant={employeeId === id ? "secondary" : "outline-secondary"}
                        >
                          {employeeId === id ? <FaChevronDown /> : <FaChevronRight />}
                        </Button>
                      </td>
                      <td>{name?.toLocaleUpperCase()}</td>
                      <td>{sales_total?.all_total?.toLocaleString() || "N/A"}</td>
                      <td>
                        <Button
                          size="sm"
                          variant={employeeId === id ? "secondary" : "outline-secondary"}
                          onClick={() => setEmployeeId(employeeId === id ? null : id)}
                        >
                          {employeeId === id ? <span>HIDE SALES</span> : <span>VIEW SALES</span>}
                        </Button>
                      </td>
                    </tr>
                    {employeeId === id && sales_monthly && (
                      <tr style={styles}>
                        <td colSpan="4">
                          <SalesLineChart data={sales_monthly} />
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No data available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

const Reports = () => {
  return (
    <>
      <NavigationBar />
      <Container fluid style={containerStyles}>
        <div className="d-flex flex-row justify-content-start">
          <div className="bg-danger me-3 mt-2" style={{ width: "1rem", height: "1rem" }}>
            {" "}
          </div>
          <h4 style={{ fontWeight: "700" }}>Business Reports</h4>
        </div>
        <Container className="mt-3">
          <Row className="g-5">
            <Col xs={12}>
              <SalesBarChart />
            </Col>
            <Col xs={12}>
              <PurchaseBarChart />
            </Col>
            <Col xs={12}>
              <CashInCashOutTable />
            </Col>
            <Col xs={12}>
              <EmployeesSalesTable />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default Reports;